/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useEffect, useState } from "react"
import {
  capitalizeFirstLetterOfEachWord,
  goToLogin,
  removeUserSession,
} from "../../Helpers/Utils/Common"
import { getName, getType } from "../../Helpers/Utils/Common"
import MenuItem from "./MenuItem"
import { toastStyle } from "../../Helpers/Utils/Common"

import toast from "react-hot-toast"

//css
import "../Navbar/Navbar.css"

//icons
import logout from "../../Assets/Images/Navbar/logout.png"
import supplies from "../../Assets/Images/Navbar/supplies.png"
import cp from "../../Assets/Images/Navbar/cp.png"
import storeReport from "../../Assets/Images/Navbar/storeReport.png"
import others from "../../Assets/Images/Navbar/agendas.png"
import expense from "../../Assets/Images/Navbar/money.png"
import project from "../../Assets/Images/Navbar/briefcase.png"
import back from "../../Assets/Images/Navbar/return.png"
import items from "../../Assets/Images/Navbar/list-items.png"
import classification from "../../Assets/Images/Navbar/classification.png"
import stock_in from "../../Assets/Images/Navbar/stock-in.png"
import stock_out from "../../Assets/Images/Navbar/stock-out.png"
import user from "../../Assets/Images/Navbar/_user.png"
import bank from "../../Assets/Images/Navbar/bank.png"
import costcenters from "../../Assets/Images/Navbar/cost_center.png"
import bu from "../../Assets/Images/Navbar/business_unit.png"
import customers from "../../Assets/Images/Navbar/customer.png"
import suppliers from "../../Assets/Images/Navbar/supplies.png"
import warehouse from "../../Assets/Images/Navbar/warehouse.png"
import history from "../../Assets/Images/Navbar/history.png"

import { useNavigate } from "react-router-dom"
import { Context } from "../../Helpers/Context/Context"

const Navbar = (props) => {
  const { businessUnitLogo, warehouseName, option, buName } =
    useContext(Context)

  const navigate = useNavigate()
  //ADMIN
  const adminMenu = [
    {
      name: "DASHBOARD",
      exact: true,
      to: "/dashboard",
      iconClassName: cp,
      expand: true,
    },
    {
      name: "BILLING",
      exact: true,
      to: "/",
      iconClassName: supplies,
      subMenus: [{ name: "Sales Invoice", to: "/billingsalesinvoice" }],
      expand: true,
    },
    {
      name: "FINANCIAL STATEMENTS",
      exact: true,
      to: "/",
      iconClassName: storeReport,
      subMenus: [
        { name: "Balance Sheet", to: "/balancesheet" },
        { name: "Profit And Loss", to: "/incomestatementreport" },
        { name: "General Ledger", to: "/generalledger" },
        { name: "Accounts Receivable", to: "/accountsreceivable" },
        { name: "Trial Balance", to: "/trialbalance" },
      ],
      expand: true,
    },
    // {
    //   name: "OPERATING EXPENSES",
    //   exact: true,
    //   to: "/",
    //   iconClassName: supplies,
    //   subMenus: [{ name: "Purchase Invoice", to: "/opexpurchaseinvoice" }],
    //   expand: true,
    // },
    // {
    //   name: "PROJECT EXPENSES",
    //   exact: true,
    //   to: "/",
    //   iconClassName: expense,
    //   subMenus: [
    //     { name: "Project Folder", to: "/projectfolder" },
    //     // { name: "Purchase Invoice", to: "/projectpurchaseinvoice" },
    //   ],
    //   expand: true,
    // },
    {
      name: "PROJECT FOLDER",
      exact: true,
      to: "/projectfolder",
      iconClassName: project,
      expand: true,
    },
    {
      name: "PURCHASE INVOICE",
      exact: true,
      to: "/projectpurchaseinvoice",
      iconClassName: expense,
      expand: true,
    },
    {
      name: "OTHERS",
      exact: true,
      to: "/",
      iconClassName: others,
      subMenus: [{ name: "JOURNAL ENTRY", to: "/journalentry" }],
      expand: true,
    },
    // {
    //   name: "MANAGE",
    //   exact: true,
    //   to: "/",
    //   iconClassName: manage,
    //   subMenus: [
    //     { name: "Suppliers", to: "/suppliers" },
    //     { name: "Customers", to: "/customers" },
    //     { name: "Business Units", to: "/businessunits" },
    //     { name: "Chart of Accounts", to: "/accountcharts" },
    //     { name: "Class", to: "/costcenters" },
    //     { name: "Banks", to: "/banks" },
    //     { name: "Users", to: "/users" },
    //   ],
    //   expand: true,
    // },
    {
      name: "BACK TO LANDING",
      exact: true,
      to: "/landing-page",
      iconClassName: back,
      expand: true,
    },
    // {
    //   name: "SALES",
    //   exact: true,
    //   to: "/",
    //   iconClassName: sales,
    //   subMenus: [
    //     { name: "Project Register", to: "/projectfolder" },
    //     { name: "Project Sales Invoice", to: "/projectpurchaseinvoice" },
    //     { name: "Project Sales", to: "/projectsales" },
    //   ],
    //   expand: true,
    // },
    // {
    //   name: "EXPENSE",
    //   exact: true,
    //   to: "/",
    //   iconClassName: supplies,
    //   subMenus: [
    //     { name: "Project Expense", to: "/projectexpense" },
    //     { name: "Operational Expenses", to: "/opexpurchaseinvoice" },
    //     { name: "Pass-through Expenses", to: "/passthroughexpenses" },
    //     {
    //       name: "Supplies Invoice",
    //       to: "/se/purchaseinvoices",
    //     },
    //     { name: "Pay Suppliers", to: "/se/paysuppliers" },
    //   ],
    //   expand: true,
    // },
    // {
    //   name: "PETTY CASH",
    //   exact: true,
    //   to: "/",
    //   iconClassName: pettycashicon,
    //   subMenus: [{ name: "Petty Cash Register", to: "/pettycash" }],
    //   expand: true,
    // },

    // {
    //   name: "SALES REPORT",
    //   exact: true,
    //   to: "/",
    //   iconClassName: reports,
    //   subMenus: [
    //     { name: "Sales Report", to: "/salesreport" },
    //     { name: "Receivables Aging Report", to: "/receivablesagingreport" }
    //   ],
    //   expand: true,
    // },

    // {
    //   name: "EXPENSES REPORT",
    //   exact: true,
    //   to: "/",
    //   iconClassName: storeReport,
    //   subMenus: [
    //     { name: "Payables Aging Report", to: "/payablesagingreport" },
    //     { name: "Expense Report", to: "/expensebytype" },
    //   ],
    //   expand: true,
    // },
  ]

  const requesterMenu = [
    {
      name: "DASHBOARD",
      exact: true,
      to: "/dashboard",
      iconClassName: cp,
      expand: true,
    },

    {
      name: "PURCHASE INVOICE",
      exact: true,
      to: "/projectpurchaseinvoice",
      iconClassName: expense,
      expand: true,
    },
    {
      name: "BACK TO LANDING",
      exact: true,
      to: "/landing-page",
      iconClassName: back,
      expand: true,
    },
  ]

  const buDirectorMenu = [
    {
      name: "DASHBOARD",
      exact: true,
      to: "/dashboard",
      iconClassName: cp,
      expand: true,
    },
    {
      name: "PROJECT FOLDER",
      exact: true,
      to: "/projectfolder",
      iconClassName: project,
      expand: true,
    },
    {
      name: "PURCHASE INVOICE",
      exact: true,
      to: "/projectpurchaseinvoice",
      iconClassName: expense,
      expand: true,
    },
    {
      name: "BACK TO LANDING",
      exact: true,
      to: "/landing-page",
      iconClassName: back,
      expand: true,
    },
  ]

  const requester = [
    {
      name: "DASHBOARD",
      exact: true,
      to: "/dashboard",
      iconClassName: cp,
      expand: true,
    },
    {
      name: "PROJECT FOLDER",
      exact: true,
      to: "/projectfolder",
      iconClassName: project,
      expand: true,
    },
    {
      name: "PURCHASE INVOICE",
      exact: true,
      to: "/projectpurchaseinvoice",
      iconClassName: expense,
      expand: true,
    },
    {
      name: "BACK TO LANDING",
      exact: true,
      to: "/landing-page",
      iconClassName: back,
      expand: true,
    },
  ]

  const financeManagerMenu = [
    {
      name: "DASHBOARD",
      exact: true,
      to: "/dashboard",
      iconClassName: cp,
      expand: true,
    },
    {
      name: "PURCHASE INVOICE",
      exact: true,
      to: "/projectpurchaseinvoice",
      iconClassName: expense,
      expand: true,
    },
    {
      name: "OTHERS",
      exact: true,
      to: "/",
      iconClassName: others,
      subMenus: [{ name: "JOURNAL ENTRY", to: "/journalentry" }],
      expand: true,
    },
    {
      name: "BACK TO LANDING",
      exact: true,
      to: "/landing-page",
      iconClassName: back,
      expand: true,
    },
  ]

  const financeHeadMenu = [
    {
      name: "DASHBOARD",
      exact: true,
      to: "/dashboard",
      iconClassName: cp,
      expand: true,
    },
    {
      name: "BILLING",
      exact: true,
      to: "/",
      iconClassName: supplies,
      subMenus: [{ name: "Sales Invoice", to: "/billingsalesinvoice" }],
      expand: true,
    },
    {
      name: "FINANCIAL STATEMENTS",
      exact: true,
      to: "/",
      iconClassName: storeReport,
      subMenus: [
        { name: "Balance Sheet", to: "/balancesheet" },
        { name: "Profit And Loss", to: "/incomestatementreport" },
        { name: "General Ledger", to: "/generalledger" },
        { name: "Accounts Receivable", to: "/accountsreceivable" },
        { name: "Trial Balance", to: "/trialbalance" },
      ],
      expand: true,
    },
    {
      name: "PROJECT FOLDER",
      exact: true,
      to: "/projectfolder",
      iconClassName: project,
      expand: true,
    },
    {
      name: "PURCHASE INVOICE",
      exact: true,
      to: "/projectpurchaseinvoice",
      iconClassName: expense,
      expand: true,
    },
    {
      name: "OTHERS",
      exact: true,
      to: "/",
      iconClassName: others,
      subMenus: [{ name: "JOURNAL ENTRY", to: "/journalentry" }],
      expand: true,
    },
    {
      name: "BACK TO LANDING",
      exact: true,
      to: "/landing-page",
      iconClassName: back,
      expand: true,
    },
  ]

  const financeOfficerMenu = [
    {
      name: "DASHBOARD",
      exact: true,
      to: "/dashboard",
      iconClassName: cp,
      expand: true,
    },
    {
      name: "BILLING",
      exact: true,
      to: "/",
      iconClassName: supplies,
      subMenus: [{ name: "Sales Invoice", to: "/billingsalesinvoice" }],
      expand: true,
    },
    {
      name: "FINANCIAL STATEMENTS",
      exact: true,
      to: "/",
      iconClassName: storeReport,
      subMenus: [{ name: "General Ledger", to: "/generalledger" }],
      expand: true,
    },
    {
      name: "PURCHASE INVOICE",
      exact: true,
      to: "/projectpurchaseinvoice",
      iconClassName: expense,
      expand: true,
    },
    {
      name: "OTHERS",
      exact: true,
      to: "/",
      iconClassName: others,
      subMenus: [{ name: "JOURNAL ENTRY", to: "/journalentry" }],
      expand: true,
    },
    {
      name: "BACK TO LANDING",
      exact: true,
      to: "/landing-page",
      iconClassName: back,
      expand: true,
    },
  ]

  //WAREHOUSE
  const warehouseMenu = [
    {
      name: "ITEM LIST",
      exact: true,
      to: "/items-list",
      iconClassName: items,
      expand: true,
    },
    {
      name: "ITEM HISTORY",
      exact: true,
      to: "/item-history",
      iconClassName: history,
      expand: true,
    },
    {
      name: "STOCK IN",
      exact: true,
      to: "/stock-in",
      iconClassName: stock_in,
      expand: true,
    },
    {
      name: "PULL OUT",
      exact: true,
      to: "/stock-out",
      iconClassName: stock_out,
      expand: true,
    },
    {
      name: "BACK TO LANDING",
      exact: true,
      to: "/warehouse-list",
      iconClassName: back,
      expand: true,
    },
  ]

  const warehouseRequesterMenu = [
    {
      name: "ITEM LIST",
      exact: true,
      to: "/items-list",
      iconClassName: items,
      expand: true,
    },
    {
      name: "STOCK IN",
      exact: true,
      to: "/stock-in",
      iconClassName: stock_in,
      expand: true,
    },
    {
      name: "PULL OUT",
      exact: true,
      to: "/stock-out",
      iconClassName: stock_out,
      expand: true,
    },
    {
      name: "BACK TO LANDING",
      exact: true,
      to: "/warehouse-list",
      iconClassName: back,
      expand: true,
    },
  ]

  const whManager = [
    {
      name: "ITEM LIST",
      exact: true,
      to: "/items-list",
      iconClassName: items,
      expand: true,
    },
    {
      name: "BACK TO LANDING",
      exact: true,
      to: "/business-units",
      iconClassName: back,
      expand: true,
    },
  ]

  const manageMenu = [
    {
      name: "CUSTOMERS",
      exact: true,
      to: "/customers",
      iconClassName: customers,
      expand: true,
    },
    {
      name: "SUPPLIERS",
      exact: true,
      to: "/suppliers",
      iconClassName: suppliers,
      expand: true,
    },
    {
      name: "BUSINESS UNITS",
      exact: true,
      to: "/businessunits",
      iconClassName: bu,
      expand: true,
    },
    ...(getType() === "admin" || getType() === "business_unit_finance_head"
      ? [
          {
            name: "CHART OF ACCOUNTS",
            exact: true,
            to: "/accountcharts",
            iconClassName: expense,
            expand: true,
          },
        ]
      : []),
    {
      name: "WH CLASSIFICATIONS",
      exact: true,
      to: "/warehouse-classifications",
      iconClassName: classification,
      expand: true,
    },
    {
      name: "CLASSES",
      exact: true,
      to: "/costcenters",
      iconClassName: costcenters,
      expand: true,
    },
    {
      name: "BANKS",
      exact: true,
      to: "/banks",
      iconClassName: bank,
      expand: true,
    },
    {
      name: "USERS",
      exact: true,
      to: "/users",
      iconClassName: user,
      expand: true,
    },
    {
      name: "WAREHOUSES",
      exact: true,
      to: "/warehouse",
      iconClassName: warehouse,
      expand: true,
    },
    {
      name: "BACK TO LANDING",
      exact: true,
      to: "/landing-page",
      iconClassName: back,
      expand: true,
    },
  ]

  const manageMenuForNonAdmins = [
    {
      name: "SUPPLIERS",
      exact: true,
      to: "/suppliers",
      iconClassName: suppliers,
      expand: true,
    },
    {
      name: "BACK TO LANDING",
      exact: true,
      to: "/landing-page",
      iconClassName: back,
      expand: true,
    },
  ]

  // logout handler
  async function handleLogout() {
    removeUserSession()
    window.localStorage.clear()
    toast.success("Logging you out...", {
      style: toastStyle(),
    })

    setTimeout(() => {
      goToLogin()
    }, 1000)
  }

  const [inactive, setInactive] = useState(true)
  const [menuItems, setMenuItems] = useState([])

  const handleExpand = (index) => {
    var list = [...menuItems]

    if (!inactive) {
      for (var i = 0; i < list.length; i++) {
        if (i !== index) {
          list[i]["expand"] = true
        }
      }

      list[index]["expand"] = !list[index]["expand"]

      setMenuItems(list)
    }
  }

  useEffect(() => {
    if (inactive) {
      removeActiveClassFromSubMenu()
    }

    props.onCollapse(inactive)
  }, [inactive])

  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll(".sub-menu").forEach((el) => {
      el.classList.remove("active")
    })
  }

  useEffect(() => {
    const type = getType()

    if (option !== "" && option !== "business_unit") {
      if (option === "warehouse" && warehouseName === "general warehouse") {
        setMenuItems(whManager)
      } else if (option === "warehouse") {
        if (getType() === "warehouse_requester") {
          setMenuItems(warehouseRequesterMenu)
        } else {
          setMenuItems(warehouseMenu)
        }
      } else if (
        option === "manage" &&
        getType() !== "admin" &&
        getType() !== "business_unit_finance_head" &&
        getType() !== "business_unit_finance_manager"
      ) {
        setMenuItems(manageMenuForNonAdmins)
      } else {
        setMenuItems(manageMenu)
      }
    } else {
      switch (type) {
        case "admin":
          setMenuItems(adminMenu)
          break
        case "requester":
          setMenuItems(requester)
          break
        case "business_unit_head":
        case "business_unit_director":
          setMenuItems(buDirectorMenu)
          break
        case "business_unit_finance_head":
        case "business_unit_finance_manager":
          setMenuItems(financeHeadMenu)
          break
        case "finance_officer":
          setMenuItems(financeOfficerMenu)
          break
        default:
          break
      }
    }

    let menuItemsList = document.querySelectorAll(".menu-item")
    menuItemsList.forEach((el, index) => {
      if (menuItems[index].name == props.active) {
        el.classList.add("active")
      }
      el.addEventListener("click", (e) => {
        const next = el.nextElementSibling
        removeActiveClassFromSubMenu()
        menuItemsList.forEach((el) => el.classList.remove("active"))
        el.classList.toggle("active")
        if (next !== null) {
          next.classList.toggle("active")
        }
      })
    })
  }, [])

  return (
    <div className={`d-none d-md-flex side-menu ${inactive ? "inactive" : ""}`}>
      <div className="top-section d-flex flex-column">
        <div
          className={`${
            inactive ? "logo-container-inactive" : "logo-container"
          }`}
        >
          <img
            src={businessUnitLogo}
            className="navbar-logo"
            onClick={() => setInactive(!inactive)}
          />
        </div>
        {option === "business_unit" && (
          <div
            className={`mb-0 mt-4 text-center font-bold text-white uppercase ${
              inactive ? "d-hidden" : "d-flex"
            }`}
          >
            {buName}
          </div>
        )}
        {option === "warehouse" && (
          <div
            className={`mb-0 mt-4 text-center font-bold text-white uppercase ${
              inactive ? "d-hidden" : "d-flex flex-column"
            }`}
          >
            {warehouseName !== "general warehouse" && <span>BU: {buName}</span>}
            WH: {warehouseName}
          </div>
        )}
      </div>

      <div className={inactive ? "main-menu" : "main-menu active-menu"}>
        {menuItems.map((menuItem, index) => (
          <MenuItem
            key={index}
            name={menuItem.name}
            exact={menuItem.exact.toString()}
            to={menuItem.to}
            subMenus={menuItem.subMenus || []}
            iconClassName={menuItem.iconClassName}
            expandManage={menuItem.expand}
            setExpandManage={handleExpand}
            index={index}
            activeSub={menuItem.name === props.active}
            onClick={(e) => {
              if (inactive) {
                setInactive(false)
              }
            }}
          />
        ))}
      </div>
      <div className="side-menu-footer">
        {!inactive && (
          <div className="user-details-footer">
            {/* <div className="account-label">Account</div> */}
            <span className="navbar-user-label">{getName()}</span>
            <br />
            <span className="user-type-label">
              {capitalizeFirstLetterOfEachWord(getType())}
            </span>
          </div>
        )}
        <div className="logout-cont" onClick={() => handleLogout()}>
          <img src={logout} className="logout-btn" />
          <span className="logout-label">LOGOUT</span>
        </div>
      </div>
    </div>
  )
}

export default Navbar
