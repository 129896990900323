import React, { useState, useEffect, useContext } from "react"
import { Col, Form, Row, Modal, Container } from "react-bootstrap"
import { Row as AntRow, Table as AntTable, Select as AntSelect } from "antd"

import { useNavigate } from "react-router-dom"
import toast from "react-hot-toast"
import Navbar from "../../Components/Navbar/Navbar"
import DeleteModal from "../../Components/Modals/DeleteModal"
import downloadIcon from "../../Assets/Images/download_icon.png"
import Select from "react-select"
import * as XLSX from "xlsx"

// api calls and utils
import {
  refreshPage,
  toastStyle,
  TokenExpiry,
  formatAmount,
  selectDropdownStyle,
  getUserId,
  getType,
} from "../../Helpers/Utils/Common"

// css
import "./Project.css"
import ConfirmPaymentModal from "./ConfirmPaymentModal"
import {
  searchProject,
  deleteProject,
} from "./../../Helpers/apiCalls/projectApi"
import { getAllCustomer } from "./../../Helpers/apiCalls/CustomerAPI"
import { getDistributor } from "../../Helpers/apiCalls/DistributorApi"
import { Context } from "../../Helpers/Context/Context"
import { UsePostRequest } from "../../Helpers/hooks/GetRequests"

export default function Project() {
  let navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const { selectedBuId } = useContext(Context)
  const [inactive, setInactive] = useState(true)
  const [allData, setAllData] = useState([])
  const [customers, setCustomers] = useState([
    { label: "All customers", value: "" },
  ])
  const [allDistributors, setAllDistributors] = useState([])
  const [showLoader, setShowLoader] = useState(false)
  /* add payment modal handler */
  const [showAddPaymentModal, setShowAddPaymentModal] = useState(false)
  const handleShowAddPaymentModal = () => setShowAddPaymentModal(true)
  const [dataSize, setDataSize] = useState(0)

  /* add payment modal handler */
  const [showConfirmPaymentModal, setShowConfirmPaymentModal] = useState(false)
  const handleCloseConfirmPaymentModal = () => setShowConfirmPaymentModal(false)

  /* delete modal handler */
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const handleCloseDeleteModal = () => setShowDeleteModal(false)
  const [searchText, setSearchText] = useState("")
  const [openViewModal, setOpenViewModal] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})

  /* FILTER CONFIGS */
  const [filterConfig, setFilterConfig] = useState({
    business_unit_id: selectedBuId,
    page: 1,
    page_size: 10,
  })

  const tableColumns = [
    {
      title: "PROJECT NAME",
      key: "name",
      dataIndex: "name",
      render: (text) => (
        <span className={`${text === "TOTALS" ? "font-bold" : ""}`}>
          {text}
        </span>
      ),
    },
    {
      title: "CUSTOMER NAME",
      key: "customer_name",
      dataIndex: "customer_name",
    },
    {
      title: "COST ESTIMATE",
      key: "cost_estimate",
      dataIndex: "cost_estimate",
      align: "right",
      width: 150,
      render: (text, record) => (
        <span className={`${record.name === "TOTALS" ? "font-bold" : ""}`}>
          {text}
        </span>
      ),
    },
    {
      title: "INTERNAL BUDGET",
      key: "internal_budget",
      dataIndex: "internal_budget",
      align: "right",
      width: 200,
      render: (text, record) => (
        <span className={`${record.name === "TOTALS" ? "font-bold" : ""}`}>
          {text}
        </span>
      ),
    },
    {
      title: "ACTUAL COST",
      key: "actual_expense",
      dataIndex: "actual_expense",
      align: "right",
      width: 150,
      render: (text, record) => (
        <span className={`${record.name === "TOTALS" ? "font-bold" : ""}`}>
          {text}
        </span>
      ),
    },
    {
      title: "BALANCE",
      key: "balance",
      dataIndex: "balance",
      align: "right",
      width: 150,
      // render: (text, record) => <span className={`${record.project_price > record.expense_budget ? "text-red" : ""} ${record.name === "TOTALS" ? "font-bold" : ""}`}>{text}</span>
      render: (text, record) => (
        <span className={`${record.name === "TOTALS" ? "font-bold" : ""}`}>
          {text}
        </span>
      ),
    },
    {
      title: "STATUS",
      key: "status",
      dataIndex: "status",
      align: "right",
      render: (text) => (
        <span style={{ color: `${text !== "open" ? "red" : "green"}` }}>
          {text}
        </span>
      ),
    },
  ]

  async function fetchCustomers(tableData) {
    setCustomers([])

    const response = await getAllCustomer()
    if (response.error) {
      if (response.error.data.status !== 404) {
        TokenExpiry(response.error)
      }
    } else {
      var _customers = response.data.data.data
        .filter((data) =>
          tableData?.some((pinv) => pinv.customer_id === data.id)
        )
        .map((supplier) => ({
          label: supplier.name,
          value: supplier.id,
        }))

      var filtered = customers.filter(
        (option) => !_customers.some((data) => data.value === option.value)
      )

      var allOptions = [...filtered, ..._customers]

      setCustomers(allOptions)
    }
  }

  async function fetchDistributors() {
    setAllDistributors([])
    const response = await getDistributor()
    if (response.data) {
      const res = response.data.data.map((row) => {
        return {
          value: row.id,
          label: row.name,
        }
      })
      setAllDistributors([{ value: "", label: "All Distributors" }, ...res])
    }
  }

  async function fetchData() {
    setShowLoader(true)
    setAllData([])

    const response = await searchProject({
      ...(getType() !== "admin" ? { user_id: getUserId() } : {}),
      ...filterConfig,
    })
    if (response.data) {
      setDataSize(response.data.size)
      var allData = response.data.data.map((data) => {
        return {
          ...data,
          org_cost_estimate: data.ce_amount,
          org_expense_budget: data.expense_budget,
          org_internal_budget: data.internal_budget,
          org_actual_expense: data.actual_expense,
          org_balance: data.balance,
          cost_estimate: formatAmount(
            parseFloat(data.ce_amount ?? 0).toFixed(2)
          ),
          expense_budget: formatAmount(data.expense_budget ?? 0),
          internal_budget: formatAmount(data.internal_budget ?? 0),
          actual_expense: formatAmount(data.actual_expense ?? 0),
          balance: formatAmount(data.balance ?? 0),
        }
      })

      var totals = allData.reduce(
        (acc, data) => {
          acc.org_cost_estimate += parseFloat(data.org_cost_estimate)
          acc.org_expense_budget += parseFloat(data.org_expense_budget)
          acc.org_internal_budget += parseFloat(data.org_internal_budget)
          acc.org_actual_expense += parseFloat(data.org_actual_expense)
          acc.org_balance += parseFloat(data.org_balance)

          acc.cost_estimate = formatAmount(acc.org_cost_estimate.toFixed(2))
          acc.internal_budget = formatAmount(acc.org_internal_budget.toFixed(2))
          acc.actual_expense = formatAmount(acc.org_actual_expense.toFixed(2))
          acc.expense_budget = formatAmount(acc.org_expense_budget.toFixed(2))
          acc.balance = acc.org_balance
            ? formatAmount(acc.org_balance.toFixed(2))
            : "0.00"

          return acc
        },
        {
          name: "TOTALS",
          customer_name: "",
          org_cost_estimate: 0,
          org_expense_budget: 0,
          org_internal_budget: 0,
          org_actual_expense: 0,
          org_balance: 0,
          cost_estimate: "",
          internal_budget: "",
          actual_expense: "",
          balance: "",
        }
      )

      setAllData([...allData, totals])
      fetchCustomers(allData)
    } else if (response.error.data.status !== 404) {
      TokenExpiry(response.error)
    }
    setShowLoader(false)
  }

  async function handleDeletePI() {
    setIsLoading(true)
    const response = await deleteProject(selectedRow.id)

    if (response.data) {
      toast.success("Project Deleted Successfully!", {
        style: toastStyle(),
      })
      setTimeout(() => refreshPage(), 1000)
    } else {
      toast.error(response?.error?.data?.messages?.response, {
        style: toastStyle(),
      })
    }
    setIsLoading(false)
  }

  async function exportExcel() {
    const tableHeaders = [
      "PROJECT NAME",
      "CUSTOMER NAME",
      "COST ESTIMATE",
      "INTERNAL BUDGET",
      "ACTUAL COST",
      "BALANCE",
    ]

    const headerSelector = [
      "name",
      "customer_name",
      "cost_estimate",
      "internal_budget",
      "actual_expense",
      "balance",
    ]
    const response = await searchProject(filterConfig)
    if (response.data) {
      var allData = response.data.data.map((data) => {
        return {
          ...data,
          org_cost_estimate: data.ce_amount,
          org_expense_budget: data.expense_budget,
          org_internal_budget: data.internal_budget,
          org_actual_expense: data.actual_expense,
          org_balance: data.balance,
          cost_estimate: formatAmount(
            parseFloat(data.ce_amount ?? 0).toFixed(2)
          ),
          expense_budget: formatAmount(data.expense_budget ?? 0),
          internal_budget: formatAmount(data.internal_budget ?? 0),
          actual_expense: formatAmount(data.actual_expense ?? 0),
          balance: formatAmount(data.balance ?? 0),
        }
      })

      var totals = allData.reduce(
        (acc, data) => {
          acc.org_cost_estimate += parseFloat(data.org_cost_estimate)
          acc.org_expense_budget += parseFloat(data.org_expense_budget)
          acc.org_internal_budget += parseFloat(data.org_internal_budget)
          acc.org_actual_expense += parseFloat(data.org_actual_expense)
          acc.org_balance += parseFloat(data.org_balance)

          acc.cost_estimate = formatAmount(acc.org_cost_estimate.toFixed(2))
          acc.internal_budget = formatAmount(acc.org_internal_budget.toFixed(2))
          acc.actual_expense = formatAmount(acc.org_actual_expense.toFixed(2))
          acc.expense_budget = formatAmount(acc.org_expense_budget.toFixed(2))
          acc.balance = acc.org_balance
            ? formatAmount(acc.org_balance.toFixed(2))
            : "0.00"

          return acc
        },
        {
          name: "TOTALS",
          customer_name: "",
          org_cost_estimate: 0,
          org_expense_budget: 0,
          org_internal_budget: 0,
          org_actual_expense: 0,
          org_balance: 0,
          cost_estimate: "",
          internal_budget: "",
          actual_expense: "",
          balance: "",
        }
      )

      var combined = [...allData, totals]

      const formattedData = [
        tableHeaders,
        ...combined.map((row) => headerSelector.map((key) => row[key])),
      ]

      const ws = XLSX.utils.aoa_to_sheet(formattedData) // Create worksheet from array
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, ws, "Projects")

      // Auto-size columns
      ws["!cols"] = tableHeaders.map(() => ({ wch: 20 })) // Adjust column width

      // Generate and trigger download
      XLSX.writeFile(wb, "Project_Data.xlsx")
    }
  }

  const handlePageChange = (pagination) => {
    setFilterConfig((prev) => ({
      ...prev,
      page: pagination.current,
    }))
  }

  useEffect(() => {
    fetchData()
  }, [filterConfig])

  useEffect(() => {
    fetchDistributors()
  }, [])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"PROJECT FOLDER"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <Row className="mb-4 g-3">
          <Col xs={12} lg={5}>
            <h1 className="page-title">PROJECT REGISTER</h1>
          </Col>
          <Col
            xs={12}
            lg={5}
            className="d-flex flex-column gap-2 flex-md-row justify-content-end"
          >
            <input
              type="search"
              name="name"
              placeholder="Search project..."
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  setFilterConfig((prev) => {
                    return { ...prev, name: searchText }
                  })
                }
              }}
              className="search-bar"
            />
            {getType() !== "requester" && (
              <button
                className="add-btn w-full"
                style={{ width: "50%" }}
                onClick={() => navigate("/projectfolder/add")}
              >
                Add
              </button>
            )}
          </Col>
          <Col xs={12} lg={2}>
            <div
              className="button-primary py-2 w-full text-center d-flex justify-content-center align-items-center"
              onClick={() => exportExcel()}
            >
              <span className="me-2">
                <img width={20} height={20} src={downloadIcon} alt=""></img>
              </span>
              <span className="pointer custom-anchor">Export To Excel</span>
            </div>
          </Col>
        </Row>

        <div className="tab-content">
          <div className="my-2 ms-2 PO-filters PI-filters d-flex justify-content-between pe-2">
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select Customer"
              styles={selectDropdownStyle}
              options={customers}
              onChange={(e) => {
                setFilterConfig((prev) => {
                  return { ...prev, customer_id: e.value, page: 1 }
                })
              }}
            />
            <AntSelect
              style={{ width: 150 }}
              placeholder="10 / PAGE"
              defaultValue={10}
              options={[
                { value: 10, label: "10 / PAGE" },
                { value: 20, label: "20 / PAGE" },
                { value: 50, label: "50 / PAGE" },
                { value: 100, label: "100 / PAGE" },
                { value: "", label: "Show All" },
              ]}
              onChange={(value) => {
                if (value === "") {
                  setFilterConfig((prev) => ({
                    ...prev,
                    page_size: "",
                    page: "",
                  }))
                } else {
                  setFilterConfig((prev) => ({
                    ...prev,
                    page_size: value,
                    page: 1,
                  }))
                }
              }}
            />
          </div>

          <div className="px-2 custom-antd-table mb-2">
            <AntTable
              size="small"
              dataSource={allData}
              columns={tableColumns}
              loading={showLoader}
              pagination={
                filterConfig.page_size !== ""
                  ? {
                      current: filterConfig.page,
                      pageSize: parseFloat(filterConfig.page_size + 1),
                      total: dataSize,
                    }
                  : false
              }
              onChange={handlePageChange} // Trigger API call on pagination change
              onRow={(record) => ({
                onClick: () => {
                  setSelectedRow(record)
                  setOpenViewModal(record)
                },
              })}
            />
          </div>
        </div>
        <div className="mb-2" />
      </div>

      <DeleteModal
        show={showDeleteModal}
        onHide={() => handleCloseDeleteModal()}
        text="project"
        onDelete={() => handleDeletePI()}
        isLoading={isLoading}
      />

      <ConfirmPaymentModal
        show={showConfirmPaymentModal}
        onHide={handleCloseConfirmPaymentModal}
        handler={handleShowAddPaymentModal}
      />

      <Modal
        footer={null}
        show={openViewModal}
        onHide={() => setOpenViewModal(false)}
        size="lg"
        centered
      >
        <Modal.Body className="">
          <div className="col-sm-12">
            <span className="custom-modal-body-title">REVIEW PROJECT</span>
          </div>
          <Container fluid className="modal-cont justify-content-center px-4">
            <Row className="mt-3">
              <Col xs={4}>
                <span className="review-form-label">Project Name</span>
                <Form.Control
                  className="review-form-input"
                  type="text"
                  value={selectedRow.name ?? ""}
                  disabled
                />
              </Col>
              <Col xs={4}>
                <span className="review-form-label">Customer</span>
                <Form.Control
                  className="review-form-input"
                  type="text"
                  value={selectedRow.customer_name ?? ""}
                  disabled
                />
              </Col>
              <Col xs={4}>
                <span className="review-form-label">Internal Budget</span>
                <Form.Control
                  className="review-form-input"
                  type="text"
                  value={selectedRow.internal_budget ?? ""}
                  disabled
                />
              </Col>
            </Row>
          </Container>

          <AntRow className="d-flex justify-content-between mt-5 px-4">
            {getType() !== "requester" && (
              <button
                className="button-primary me-2"
                onClick={() => {
                  navigate("print/" + selectedRow.id)
                }}
              >
                View
              </button>
            )}

            <div>
              <button
                onClick={() => {
                  setSelectedRow({})
                  setOpenViewModal(false)
                }}
                className="me-2 button-default"
              >
                Cancel
              </button>
              {getType() !== "requester" && (
                <>
                  <button
                    className="button-warning-fill me-2"
                    onClick={() => {
                      setShowDeleteModal(true)
                    }}
                  >
                    Delete
                  </button>
                  <button
                    className="button-primary me-2"
                    onClick={() => {
                      setOpenViewModal(false)
                      navigate("edit/" + selectedRow.id)
                    }}
                  >
                    Edit
                  </button>
                </>
              )}
            </div>
          </AntRow>
        </Modal.Body>
      </Modal>
    </div>
  )
}
