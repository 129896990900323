import { getToken, getType, getUser, getUserId } from "../Utils/Common"
import { getAPICall, postAPICall2 } from "./axiosMethodCalls"

const user = getUser()
const userId = getUserId()
const token = getToken()
const type = getType()

export const deleteProject = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/delete",
      {
        requester: getUser(),
        token: getToken(),
        project_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getAllProjects = async (data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/get_all_project",
      {
        requester: user,
        token: token,
        business_unit_id: data.business_unit_id,
        screen: data.screen,
        status: data.status,
        ...(data.screen === "pinv" &&
          type === "requester" && {
            user_id: userId,
          }),
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getLastSubtypes = async (data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "expense_types/get_all_last_expense_type",
      {
        requester: user,
        token: token,
        screen: data.screen,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getProject = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/get_project",
      {
        requester: user,
        token: token,
        project_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const searchProject = async (filter) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/search",
      {
        requester: getUser(),
        token: getToken(),
        ...filter,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const searchProjectInv = async (name) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_invoices/search",
      {
        requester: getUser(),
        token: getToken(),
        project_id: name,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getProjectTypes = async () => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "projects/get_project_type_names",
      {
        requester: getUser(),
        token: getToken(),
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error }
  }
}

export const getAllProjectInv = async (data) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_invoices/search",
      {
        requester: getUser(),
        token: getToken(),
        ...data,
        date_from: data.date_from,
        date_to: data.date_to,
        due_date_from: data.due_date_from,
        due_date_to: data.due_date_to,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

// GET SINGLE PURCHASE INVOICE
export const getSingleProjectPI = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_invoices/get_project_invoice",
      {
        requester: getUser(),
        token: getToken(),
        project_invoice_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const getPinvHistory = async (id) => {
  try {
    const response = await getAPICall(
      process.env.REACT_APP_LINK + "project_invoices/get_history",
      {
        requester: getUser(),
        token: getToken(),
        project_invoice_id: id,
      }
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}

export const changeProjectPiStatus = async (
  ids,
  status,
  remarks,
  retract_remarks
) => {
  let form_data = new FormData()
  form_data.append("requester", getUser())
  form_data.append("token", getToken())
  form_data.append("status", status)
  form_data.append("remarks", remarks ?? "")
  form_data.append("retract_remarks", retract_remarks ?? "")

  ids.forEach((id) => {
    form_data.append("project_invoice_id[]", id)
  })

  try {
    const response = await postAPICall2(
      process.env.REACT_APP_LINK + "project_invoices/record_status_change",
      form_data
    )
    return { data: response.data }
  } catch (error) {
    return { error: error.response }
  }
}
