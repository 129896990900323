import React, { useContext, useEffect, useState } from "react"
import { Button, Col, Form, Row, Table } from "react-bootstrap"
import {
  Select as AntSelect,
  DatePicker,
  Divider,
  Input,
  Space,
  Upload,
} from "antd"
import { useNavigate, useParams } from "react-router-dom"
import Navbar from "../../../Components/Navbar/Navbar"
import trash from "../../../Assets/Images/trash.png"
import {
  formatAmount,
  toastStyle,
  useQuery,
  base64ToFile,
  getType,
  handleCopy,
  sanitizeValues,
} from "../../../Helpers/Utils/Common"
import toast from "react-hot-toast"
import { getProjectInvoice } from "../../../Helpers/apiCalls/ProjectInvoiceApi"
import { UsePostRequest } from "../../../Helpers/hooks/UsePostApiCall"
import { createSupplier } from "../../../Helpers/apiCalls/suppliersApi"
import {
  getAllProjects,
  getLastSubtypes,
} from "../../../Helpers/apiCalls/projectApi"
import { getDistributor } from "../../../Helpers/apiCalls/DistributorApi"
import upload from "../../../Assets/Images/upload.png"
import AddModal from "../../../Components/Modals/AddModal"
import ReactLoading from "react-loading"
import dayjs from "dayjs"
import { UseGetRequest } from "../../../Helpers/hooks/GetRequests"
import { Context } from "../../../Helpers/Context/Context"
import { ContentCopy, PlusOneOutlined } from "@mui/icons-material"
const { Dragger } = Upload

function FormOperationalExpenses({ add, edit }) {
  const [isSaveClicked, setIsSaveClicked] = useState(false)
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const [customClass, setCustomClass] = useState("")
  const [addVendorIsSaveClicked, setAddVendorIsSaveClicked] = useState(false)
  const navigate = useNavigate()
  const { id, type } = useParams()

  const query = useQuery()
  const replicate = query.get("replicate")

  const [distributors, setDistributors] = useState([])
  const [fileAttachments, setFileAttachments] = useState([])
  const [ceNumbers, setCeNumbers] = useState([])
  const [projects, setProjects] = useState([])

  const vatTypeOptions = [
    {
      label: "VAT IN Service",
      value: "vat_in_service",
    },
    {
      label: "VAT IN Goods",
      value: "vat_in_goods",
    },
    {
      label: "VAT EX Service",
      value: "vat_ex_service",
    },
    {
      label: "VAT EX Goods",
      value: "vat_ex_goods",
    },
    {
      label: "Zero Rated",
      value: "non_vat",
    },
  ]

  const whtOptionsGoods = [
    { value: "WC158_1", label: "WC158 EWT Goods (1%)" },
    { value: "WI158_1", label: "WI158 EWT Goods (1%)" },
  ]

  const whtOptionsService = [
    { value: "W000_0", label: "0" },
    { value: "WC010_10", label: "WC010 EWT Professionals (10%)" },
    { value: "WC100_5", label: "WC100 EWT Rentals (5%)" },
    { value: "WC120_2", label: "WC120 EWT Services (2%)" },
    { value: "WC160_2", label: "WC160 EWT Services (2%)" },
    { value: "WI160_2", label: "WI160 EWT Services (2%)" },
    { value: "WI010_5", label: "WI010 EWT Professionals (5%)" },
    { value: "WI011_10", label: "WI011 EWT Professionals (10%)" },
    { value: "WI100_5", label: "WI100 EWT Rentals (5%)" },
  ]

  async function fetchUsers() {
    setDistributors([])

    const response = await getDistributor("")
    if (response.data) {
      const result = response.data.data?.map((data) => {
        return {
          value: data.id,
          label: data.name,
          name: "requisitioner",
        }
      })

      setDistributors(result)
    }
  }

  async function fetchAllProjects() {
    const response = await getAllProjects({
      business_unit_id: selectedBuId,
      screen: "pinv",
      ...(add
        ? {
            status: "open",
          }
        : {}),
    })
    if (response.data) {
      const _projects = response.data.data.map((item) => {
        return {
          name: "project_name",
          value: item.id,
          label: item.name,
        }
      })
      setProjects(_projects)
      return _projects
    } else {
      setProjects([])
      return []
    }
  }

  async function fetchLastSubtypes() {
    const response = await getLastSubtypes({ screen: "pinv" })
    if (response.data) {
      const ceNos = response.data.data.flatMap((item) => {
        return item?.last_types?.map((subtype) => ({
          name: "ce_no",
          value: subtype.id,
          label: subtype.name,
        }))
      })
      setCeNumbers(ceNos)
    } else {
      setCeNumbers([])
    }
  }

  const [supplierDetails, setSupplierDetails] = useState({
    trade_name: "",
    trade_address: "",
    bir_name: "",
    bir_address: "",
    tin: "",
    terms: "",
    requirements: "",
    phone_no: "",
    email: "",
    contact_person: "",
    bank_primary: "",
    bank_alternate: "",
    payee: "",
  })

  const [showAddSupplierModal, setShowAddSupplierModal] = useState(false)
  const handleShowAddSupplierModal = () => setShowAddSupplierModal(true)
  const handleCloseAddSupplierModal = () => {
    setShowAddSupplierModal(false)
  }

  const [inactive, setInactive] = useState(true)
  const [costCenter, setCostCenter] = useState([])
  const [originalCostCenter, setOriginalCostCenter] = useState()
  const [showLoader, setShowLoader] = useState(false)
  const [suppliers, setSuppliers] = useState([])
  const [expenses, setExpenses] = useState([])
  const { selectedBuId } = useContext(Context)
  const [formValues, setFormValues] = useState({
    requisitioner: "",
    supplier_id: "",
    type: "",
    vendor_id: "",
    expense_type_id: "",
    remark: "",
    grand_total: 0,
    is_save: "",
    discount: "0",
    business_unit_id: selectedBuId,
  })

  const [purchasedItems, setPurchasedItems] = useState([
    {
      item_name: "",
      wht_percent_code: null,
      qty: 0,
      unit: "",
      price: 0,
      discount: 0,
      vat_in: 0,
      wht_percent: 0,
      wht_amount: 0,
      type: "",
      ce_no: "",
      class: "",
      grand_total: 0,
      expense_type_id: "",
      remarks: "",
    },
  ])

  const [totalSummary, setTotalSummary] = useState({
    subtotal: 0,
    discount: 0,
    wht_percent: 0,
    wht_amount: 0,
    other_fees: 0,
    grand_total: 0,
    particulars_wht: 0,
    particulars_wht_amount: 0,
    particulars_discount: 0,
  })

  const [supplierValue, setSupplierValue] = useState({
    name: "supplier_id",
    label: "",
    value: "",
  })

  const [requisitionerValue, setRequisitionerValue] = useState({
    name: "requisitioner",
    label: "",
    value: "",
  })

  // const [isError, setIsError] = useState({
  //   purchased_items: false,
  // });

  const [supplierHasUpdated, setSupplierHasUpdated] = useState(false)

  async function handleAddSupplier() {
    // if (validateSuppliers(supplierDetails, setIsSupplierError)) {
    setAddVendorIsSaveClicked(true)
    const response = await createSupplier(supplierDetails)
    if (response.response) {
      toast.success(response.response.response, {
        style: toastStyle(),
      })
      setSupplierHasUpdated(!supplierHasUpdated)
      handleCloseAddSupplierModal()
      setAddVendorIsSaveClicked(false)
      setSupplierDetails({})
    } else {
      setAddVendorIsSaveClicked(false)
      toast.error("Error Creating New Vendor", {
        style: toastStyle(),
      })
    }
    // }
  }

  function AddItem() {
    const newItem = {
      item_name: "",
      qty: 0,
      unit: "",
      price: 0,
      discount: 0,
      vat_in: 0,
      wht_amount: 0,
      type: "",
      ce_no: "",
      class: "",
      grand_total: 0,
      expense_type_id: "",
      remarks: "",
    }
    setPurchasedItems((prevItems) => [...prevItems, newItem])
  }

  function twoDecimals(number) {
    return parseFloat(number ?? 0).toFixed(2)
  }

  function handleItemChange(field, value, index) {
    var tempItems = [...purchasedItems]
    tempItems[index][field] = value
    if (field === "price" || field === "wht_percent" || field === "vat_type") {
      var originalVal =
        tempItems[index].price !== "" ? tempItems[index].price.toString() : "0"
      var cleaned = parseFloat(originalVal.replace(/,/g, ""))
      var tempGross = cleaned

      var tempVatIn =
        cleaned !== "" && cleaned !== null ? parseFloat(cleaned) * 1.12 : "0.00"
      var addedVatIn = tempVatIn - cleaned
      var tempWthAmount =
        tempGross * (parseFloat(tempItems[index].wht_percent || 0) / 100)
      var vatExTempWthAmount =
        tempGross * (parseFloat(tempItems[index].wht_percent || 0) / 100)

      if (field === "vat_type") {
        tempVatIn = "0.00"
        addedVatIn = "0.00"
        vatExTempWthAmount = "0.00"
        tempWthAmount = "0.00"
        tempItems[index].wht_amount = "0.00"
        tempItems[index].wht_percent = null
        tempItems[index].wht_percent_code = null
      }

      var grandTotal =
        tempItems[index].vat_type === "vat_in_goods" ||
        tempItems[index].vat_type === "vat_in_service"
          ? tempVatIn - tempWthAmount
          : tempItems[index].vat_type === "vat_ex_service" ||
            tempItems[index].vat_type === "vat_ex_goods"
          ? tempGross - vatExTempWthAmount
          : tempGross

      if (field === "vat_type") {
        grandTotal = tempGross
      }

      tempItems[index].vat_in =
        tempItems[index].vat_type !== "vat_ex_service" &&
        tempItems[index].vat_type !== "vat_ex_goods" &&
        tempItems[index].vat_type !== "non_vat"
          ? twoDecimals(tempVatIn)
          : 0
      tempItems[index].vat_in_display =
        tempItems[index].vat_type !== "vat_ex_service" &&
        tempItems[index].vat_type !== "vat_ex_goods" &&
        tempItems[index].vat_type !== "non_vat"
          ? twoDecimals(addedVatIn)
          : 0
      tempItems[index].wht_amount =
        tempItems[index].vat_type !== "non_vat"
          ? twoDecimals(tempWthAmount)
          : "0.00"
      tempItems[index].grand_total = grandTotal
    } else if (field === "ce_no") {
      tempItems[index].ce_nos_id = value
    } else if (field === "class") {
      tempItems[index].class = value
    }
    setPurchasedItems(tempItems)
  }

  function handleRemoveItem(id) {
    setShowLoader(true)
    const rowId = id
    const newItemList = [...purchasedItems]
    newItemList.splice(rowId, 1)
    setPurchasedItems(newItemList)

    const tempSubTotal = newItemList.reduce((a, b) => {
      return a + parseFloat(b.price)
    }, 0)
    const tempWhtTotal = newItemList.reduce((a, b) => {
      return a + parseFloat(b.wht_percent)
    }, 0)
    const tempVatInTotal = newItemList.reduce((a, b) => {
      return a + parseFloat(b.vat_in)
    }, 0)
    const tempItemDiscounts = newItemList.reduce((a, b) => {
      return a + parseFloat(b.discount)
    }, 0)
    const tempWhtAmountTotal = newItemList.reduce((a, b) => {
      return a + parseFloat(b.wht_amount)
    }, 0)
    setTotalSummary((prev) => ({
      ...prev,
      subtotal: tempSubTotal,
      particulars_wht: tempWhtTotal,
      particulars_discount: tempItemDiscounts > 0 ? 0 : prev.discount,
      wht_percent: tempWhtTotal > 0 ? 0 : prev.wht_percent,
      wht_amount: tempWhtTotal > 0 ? tempWhtAmountTotal : prev.wht_amount,
      vat_in_total: tempVatInTotal ?? "0.00",
    }))
    setFormValues((prev) => ({
      ...prev,
      discount: tempItemDiscounts > 0 ? 0 : prev.discount,
    }))
    setTimeout(() => setShowLoader(false), 1)
  }

  function handleSEChange(e) {
    const { name, value } = e.target
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  function handleSubmit() {
    if (edit && replicate === "true") {
      handleCreate()
    } else if (add) {
      handleCreate()
    } else if (edit) {
      handleUpdate()
    }
  }

  /** GET API - Suppliers **/
  const fetchSuppliers = async (searchText) => {
    if (searchText.length > 3) {
      const response = await UseGetRequest(`suppliers/get_all_supplier`, {
        term: searchText,
      })

      if (response.data) {
        const suppliers = response?.data?.data.map((supplier) => ({
          name: "supplier_id",
          label: supplier.trade_name,
          value: supplier.id,
        }))

        setSuppliers(suppliers) // Set the new list of suppliers at once
      }
    }
  }

  /** GET API - ExpenseType**/

  async function fetchExpenseType() {
    setExpenses([])
    const accountTypes = await UseGetRequest(
      "expense_types/get_all_expense_type"
    )

    if (accountTypes.data && Array.isArray(accountTypes.data.data)) {
      const mainTypes = accountTypes.data.data.map((row) => ({
        value: row.id,
        label: row.name,
      }))

      setExpenses(mainTypes)
    } else {
      setExpenses([])
    }
  }

  /** POST API - Save new purchase invoice **/
  async function handleCreate() {
    if (isSaveClicked || isSubmitClicked) {
      return
    }
    const userType = getType()
    setIsSubmitClicked(true)
    let tempStatus = "pending"
    if (userType === "admin") {
      tempStatus = "finalized"
    } else if (userType === "business_unit_finance_manager") {
      tempStatus = "reviewed"
    } else if (userType === "business_unit_finance_head") {
      tempStatus = "approved"
    }

    const payload = {
      requisitioner: formValues.requisitioner,
      distributor_id: formValues.business_unit_id,
      supplier_id: formValues.supplier_id,
      request_date: formValues.request_date.format("YYYY-MM-DD"),
      due_date: formValues.due_date
        ? formValues.due_date.format("YYYY-MM-DD")
        : "",
      is_save: "0",
      wht_percent: totalSummary.wht_percent_code || "",
      wht_amount: totalSummary.wht_amount || "",
      grand_total: totalSummary.grand_total,
      subtotal: totalSummary.subtotal,
      discount: totalSummary.discount,
      other_fees: totalSummary.other_fees,
      status: tempStatus,
      vat_type: formValues.vat_type,
      vat_in_type: formValues.vat_in_type,
      remark: formValues.remark ?? "",

      file: fileAttachments.map((file) => file),

      names: purchasedItems.map((item) => item.item_name),
      quantities: purchasedItems.map((item) => item.qty),
      units: purchasedItems.map((item) => item.unit),
      prices: purchasedItems.map((item) => sanitizeValues(item.price)),
      discounts: purchasedItems.map((item) => item.discount),
      vat_ins: purchasedItems.map((item) => item.vat_in),
      vat_types: purchasedItems.map((item) => item.vat_type),
      wht_percents: purchasedItems.map((item) => item.wht_percent_code || ""),
      wht_amounts: purchasedItems.map((item) => item.wht_amount),
      grand_totals: purchasedItems.map((item) => item.grand_total),
      expense_type_ids: purchasedItems.map((item) => item.expense_type_id),
      remarks: purchasedItems.map((item) => item.remarks) ?? "",
      ce_nos: purchasedItems.map((item) => item.ce_no),
      class_ids: purchasedItems.map((item) => item.class),
    }
    const formDataValues = Object.entries(payload).map(([key, value]) => ({
      key,
      value,
    }))

    const response = await UsePostRequest(
      "project_invoices/create",
      formDataValues,
      true
    )
    if (response?.data?.status === "success") {
      toast.success("Purchase Invoice Created Successfully!", {
        style: toastStyle(),
      })
      setTimeout(() => {
        navigate(
          "/projectpurchaseinvoice/review/" + response.data.project_invoice_id
        )
      }, 500)
    } else {
      let message = response?.error?.response?.data?.messages?.response
      toast.error(message, {
        style: toastStyle(),
      })
    }
    setIsSubmitClicked(false)
    // }
  }

  /** POST API - Edit old purchase invoice **/
  async function handleUpdate() {
    if (isSaveClicked || isSubmitClicked) {
      return
    }

    setIsSaveClicked(true)
    setIsSubmitClicked(true)

    // can't send all sa payload para di confusing sa backend
    const payload = {
      project_invoice_id: formValues.id,
      requisitioner: formValues.requisitioner,
      // project_id: formValues.project_id,
      distributor_id: formValues.business_unit_id,
      supplier_id: formValues.supplier_id,
      request_date: formValues.request_date.format("YYYY-MM-DD"),
      due_date: formValues.due_date
        ? formValues.due_date.format("YYYY-MM-DD")
        : "",
      remark: formValues.remark ?? "",
      is_save: "0",
      vat_type: formValues.vat_type,
      vat_in_type: formValues.vat_in_type,
      wht_percent: totalSummary.wht_percent,
      wht_amount:
        formValues.vat_type !== "zero_rated" ? totalSummary.wht_amount : 0,
      grand_total: totalSummary.grand_total,
      subtotal: totalSummary.subtotal,
      discount: totalSummary.discount,
      other_fees: totalSummary.other_fees,
      status: formValues.status,
      file: fileAttachments.map((file) => file),
      names: purchasedItems.map((item) => item.item_name),
      quantities: purchasedItems.map((item) => item.qty),
      units: purchasedItems.map((item) => item.unit),
      prices: purchasedItems.map((item) => item.price),
      discounts: purchasedItems.map((item) => item.discount),
      vat_ins: purchasedItems.map((item) => item.vat_in),
      wht_percents: purchasedItems.map((item) => item.wht_percent_code),
      wht_amounts: purchasedItems.map((item) => {
        if (formValues.vat_type === "zero_rated") {
          return 0
        } else {
          return item.wht_amount
        }
      }),
      vat_types: purchasedItems.map((item) => item.vat_type),
      grand_totals: purchasedItems.map((item) => item.grand_total),
      expense_type_ids: purchasedItems.map((item) => item.expense_type_id),
      remarks: purchasedItems.map((item) => item.remarks) ?? "",
      ce_nos: purchasedItems.map((item) => item.ce_no),
      class_ids: purchasedItems.map((item) => item.class),
    }
    const formDataValues = Object.entries(payload).map(([key, value]) => ({
      key,
      value,
    }))

    const response = await UsePostRequest(
      "project_invoices/update",
      formDataValues,
      true
    )
    if (response.data) {
      toast.success("Purchase Invoice Updated Successfully!", {
        style: toastStyle(),
      })
      setTimeout(() => {
        navigate("/projectpurchaseinvoice/review/" + id)
      }, 500)
    } else {
      toast.error("Error Updating Purchase Invoice", {
        style: toastStyle(),
      })
    }
  }

  /** GET API - Get single purchase invoice **/
  async function fetchSingleOperationalExpense(id) {
    const response = await getProjectInvoice(id)
    if (response.data) {
      var data = response.data.data[0]
      data.request_date = dayjs(data.request_date)
      data.due_date = dayjs(data.due_date)

      setFormValues(data)

      const items = data.project_invoice_items.map((item) => {
        var whtPercent
        if (data.vat_in_type === "goods") {
          whtPercent = whtOptionsGoods?.find((option) =>
            option.value.includes(item.wht_percent)
          )?.value
        } else {
          whtPercent = whtOptionsService?.find((option) =>
            option.value.includes(item.wht_percent)
          )?.value
        }
        var vatInAdded = parseFloat(item.vat_in) - parseFloat(item.price)

        return {
          ...item,
          item_name: item.name,
          vat_in_display: vatInAdded,
          wht_percent: parseFloat(whtPercent?.split("_")[1]),
          wht_percent_code: item.wht_percent,
          expense_type_id: item.expense_type_id,
          class: item.class_id,
          ce_no: item.project_ce_id,
        }
      })

      var whtPercent =
        data.vat_in_type === "goods"
          ? whtOptionsGoods.find((option) =>
              option.value.includes(data.wht_percent)
            )?.value
          : whtOptionsService.find((option) =>
              option.value.includes(data.wht_percent)
            )?.value

      setTotalSummary((prev) => ({
        ...prev,
        subtotal: parseFloat(data.subtotal ?? 0),
        discount: parseFloat(data.discount ?? 0),
        grand_total: parseFloat(data.grand_total ?? 0),
        wht_amount: parseFloat(data.wht_amount ?? 0),
        wht_percent_value: whtPercent,
        particulars_wht: items.reduce(
          (total, item) => total + parseFloat(item.wht_percent),
          0
        ),
      }))

      if (
        data.project_invoice_attachment &&
        data.project_invoice_attachment.length > 0
      ) {
        const tempFiles = data.project_invoice_attachment?.map((file) => {
          let tempFile = base64ToFile(file.base_64, file.name)
          return tempFile
        })
        setFileAttachments(tempFiles)
      } else {
        setFileAttachments([])
      }

      setPurchasedItems(items)
      setSupplierValue({
        name: data.supplier_trade_name ? "supplier_id" : "vendor_id",
        label: data.supplier_trade_name || data.vendor_trade_name,
        value: data.supplier_id || data.vendor_id,
      })

      setRequisitionerValue({
        name: "requisitioner",
        label: data.distributor_name,
        value: data.distributor_id,
      })
    }
  }

  const handleBeforeUpload = (file) => {
    const isSizeValid = file.size / 1024 / 1024 < 1 // Check if file size is less than 1MB
    if (!isSizeValid) {
      toast.error("File cannot exceed 1MB")
      return Upload.LIST_IGNORE
    } else {
      setFileAttachments([...fileAttachments, file])
    }
    return false
  }

  const handleRemove = (selectedFile) => {
    var newlist = fileAttachments.filter((file) => {
      return selectedFile.uid !== file.uid
    })
    setFileAttachments(newlist)
  }

  async function fetchAllExpenseType() {
    setShowLoader(true)
    const response = await UseGetRequest("cost_centers/get_all_cost_center")
    if (response.data) {
      setCostCenter(response.data.data)
      setOriginalCostCenter(response.data.data)
    } else {
      setCostCenter([])
    }
    setShowLoader(false)
  }

  async function handleAddCustomClass() {
    const response = await UsePostRequest("cost_centers/create", {
      name: customClass,
    })
    var custom
    if (response.data) {
      toast.success(response.data.response, {
        style: toastStyle(),
      })
      custom = {
        id: response.data.id,
        name: customClass,
      }
    }

    var updatedOptions = [...costCenter, custom]
    setCostCenter(updatedOptions)
    setCustomClass("")
  }

  React.useEffect(() => {
    fetchAllExpenseType()
  }, [supplierHasUpdated])

  React.useEffect(() => {
    if (edit) {
      fetchSingleOperationalExpense(id)
    }
    fetchExpenseType()
    fetchUsers()
    fetchAllProjects()
    fetchLastSubtypes()
  }, [])

  useEffect(() => {
    // recalculate the totals
    var tempItems = purchasedItems
    var tempSubTotal = tempItems.reduce((a, b) => {
      var cleaned = parseFloat(sanitizeValues(b.price))
      return a + cleaned
    }, 0)
    // var tempWhtTotal = tempItems.reduce((a, b) => {
    //   return a + parseFloat(b.wht_percent)
    // }, 0)
    var tempWhtAmountTotal = tempItems.reduce((a, b) => {
      return a + parseFloat(b.wht_amount)
    }, 0)
    var tempVatInTotal = tempItems.reduce((a, b) => {
      return a + parseFloat(b.vat_in_display)
    }, 0)

    // if (totalSummary.wht_percent_value) {
    //   tempWhtAmountTotal = tempSubTotal * (totalSummary.wht_percent / 100)
    // }

    var grandTotal = tempItems.reduce((a, b) => {
      return a + parseFloat(b.grand_total)
    }, 0)

    // var grandTotal
    // if (formValues.vat_type === "vat_in") {
    //   grandTotal = tempSubTotal + tempVatInTotal - tempWhtAmountTotal
    // } else if (formValues.vat_type === "vat_ex") {
    //   grandTotal = tempSubTotal - tempWhtAmountTotal
    // } else if (formValues.vat_type === "zero_rated") {
    //   grandTotal = tempSubTotal
    // }

    setTotalSummary((prev) => ({
      ...prev,
      subtotal: tempSubTotal,
      // particulars_wht: tempWhtTotal,
      // particulars_wht_amount: tempWhtAmountTotal,
      wht_amount: tempWhtAmountTotal ?? prev.wht_amount,
      vat_in_total: tempVatInTotal ?? "0.00",
      grand_total: grandTotal,
    }))
    setFormValues((prev) => ({
      ...prev,
      grand_total: 100,
    }))
  }, [
    purchasedItems,
    formValues.vat_type,
    totalSummary.wht_percent,
    totalSummary.wht_percent_value,
    formValues.vat_in_type,
  ])

  // VALIDATION STUFF
  function itemValidationPassed() {
    if (!purchasedItems || purchasedItems.length === 0) {
      return false
    }

    const isInvalid = purchasedItems.some(
      (item) =>
        !item?.price ||
        String(item?.price).trim() === "" || // Ensure price is checked as a string
        !item?.expense_type_id ||
        String(item?.expense_type_id).trim() === "" || // Ensure expense_type_id is checked as a string
        !item?.class ||
        String(item?.class).trim() === "" || // Ensure class is checked as a string
        !item?.ce_no ||
        String(item?.ce_no).trim() === "" // Ensure ce_no is checked as a string
    )

    return !isInvalid
  }

  const [submittableForm, setSubmittableForm] = useState(false)

  React.useEffect(() => {
    const requiredFieldKeys = [
      "supplier_id",
      "business_unit_id",
      "request_date",
      "due_date",
      "vat_type",
      "vat_in_type",
    ]
    const itemValidation = itemValidationPassed()
    let isValid = true
    requiredFieldKeys.forEach((key) => {
      if (
        !formValues[key] ||
        (formValues[key] &&
          typeof formValues[key] === "string" &&
          formValues[key]?.trim() === "")
      ) {
        isValid = false
      }
    })
    setSubmittableForm(isValid && itemValidation)
  }, [formValues, purchasedItems])

  // function handleVatTypeChange(vatType) {
  //   const updatedList = purchasedItems.map((item) => {
  //     // Ensure we have a valid price to work with

  //     //sanitize the price to remove the commas for calculation
  //     var originalVal = item.price !== "" ? item.price.toString() : "0"
  //     var cleaned = parseFloat(originalVal.replace(/,/g, ""))
  //     var tempGrossTotal = cleaned

  //     var tempVatIn =
  //       cleaned !== "" && cleaned !== null ? parseFloat(cleaned) * 1.12 : 0
  //     var addedVatIn = tempVatIn - parseFloat(cleaned)
  //     var tempWthAmount =
  //       tempGrossTotal * (parseFloat(item.wht_percent ?? 0) / 100)

  //     var grandTotal =
  //       vatType === "vat_in"
  //         ? twoDecimals(tempVatIn - tempWthAmount)
  //         : vatType === "vat_ex"
  //         ? twoDecimals(tempGrossTotal - tempWthAmount)
  //         : vatType === "zero_rated"
  //         ? twoDecimals(tempGrossTotal)
  //         : 0

  //     return {
  //       ...item,
  //       grand_total: grandTotal,
  //       vat_in: vatType === "vat_in" ? twoDecimals(addedVatIn) : 0,
  //       wht_amount: twoDecimals(tempWthAmount),
  //     }
  //   })

  //   setPurchasedItems(updatedList)
  // }

  // function handleVatInTypeChange() {
  //   setTotalSummary((prev) => ({
  //     wht_percent: null,
  //     wht_percent_value: null,
  //   }))

  //   const updatedList = purchasedItems.map((item) => {
  //     // Ensure we have a valid price to work with

  //     //sanitize the price to remove the commas for calculation
  //     var originalVal = item.price !== "" ? item.price.toString() : "0"
  //     var cleaned = parseFloat(originalVal.replace(/,/g, ""))
  //     var tempGrossTotal = cleaned

  //     var tempVatIn =
  //       cleaned !== "" && cleaned !== null ? parseFloat(cleaned) * 1.12 : 0
  //     var addedVatIn = tempVatIn - parseFloat(cleaned)
  //     var tempWthAmount = tempGrossTotal * (0 / 100)
  //     var vatExTempWthAmount = tempGrossTotal * (0 / 100)
  //     var grandTotal =
  //       formValues.vat_type === "vat_ex"
  //         ? twoDecimals(tempVatIn - tempWthAmount - addedVatIn)
  //         : formValues.vat_type === "vat_in"
  //         ? twoDecimals(tempGrossTotal - vatExTempWthAmount + addedVatIn)
  //         : tempGrossTotal

  //     return {
  //       ...item,
  //       wht_percent_code: null,
  //       wht_percent: null,
  //       grand_total: grandTotal,
  //       wht_amount: twoDecimals(tempWthAmount),
  //       vat_in_display:
  //         formValues.vat_type === "vat_in" ? twoDecimals(addedVatIn) : 0,
  //     }
  //   })

  //   setPurchasedItems(updatedList)
  // }

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"PURCHASE INVOICE"}
        />
      </div>
      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <div className="row">
          <h1 className="page-title mb-4">
            {!replicate
              ? `${add ? "ADD" : "EDIT"} PURCHASE INVOICE`
              : "ADD PURCHASE INVOICE"}
          </h1>
        </div>

        {/* content */}

        <div className="edit-form mt-2">
          <Row className="pt-3 mb-2 d-flex flex-column flex-md-row align-items-start">
            {/* <Col span={12}>
              <span className="edit-label">
                VAT TYPE <label className="badge-required">{` *`}</label>
              </span>
            </Col>
            <Col xs={12} className="mb-3">
              <Form.Check
                inline
                label="VAT In"
                name="vat_type"
                type="radio"
                value="vat_in"
                checked={formValues.vat_type === "vat_in"}
                onClick={(e) => {
                  setTotalSummary((prev) => ({
                    ...prev,
                    vat_type: "vat_in",
                  }))
                  if (e.target.checked) {
                    handleVatTypeChange("vat_in")
                    setFormValues((prev) => ({
                      ...prev,
                      vat_type: "vat_in",
                    }))
                  }
                }}
              />
              <Form.Check
                inline
                label="VAT Ex"
                name="vat_type"
                type="radio"
                value="vat_ex"
                checked={formValues.vat_type === "vat_ex"}
                onClick={(e) => {
                  setTotalSummary((prev) => ({
                    ...prev,
                    vat_type: "vat_ex",
                  }))
                  if (e.target.checked) {
                    handleVatTypeChange("vat_ex")
                    setFormValues((prev) => ({
                      ...prev,
                      vat_type: "vat_ex",
                    }))
                  }
                }}
              />
              <Form.Check
                inline
                label="ZERO RATED"
                name="zero_rated"
                type="radio"
                value="zero_rated"
                checked={formValues.vat_type === "zero_rated"}
                onClick={(e) => {
                  setTotalSummary((prev) => ({
                    ...prev,
                    vat_type: "zero_rated",
                  }))
                  if (e.target.checked) {
                    handleVatTypeChange("zero_rated")
                    setFormValues((prev) => ({
                      ...prev,
                      vat_type: "zero_rated",
                    }))
                  }
                }}
              />
            </Col>

            <Col
              span={12}
              className={`mb-3 ${
                formValues.vat_type === "zero_rated" && "hidden"
              }`}
            >
              <span className="edit-label">
                TYPE <label className="badge-required">{` *`}</label>
              </span>
            </Col>
            <Col
              xs={12}
              className={`mb-3 ${
                formValues.vat_type === "zero_rated" && "hidden"
              }`}
            >
              <Form.Check
                inline
                label="Goods"
                name="vat_in_type"
                type="radio"
                checked={formValues.vat_in_type === "goods"}
                onClick={(e) => {
                  if (e.target.checked) {
                    setFormValues((prev) => ({
                      ...prev,
                      vat_in_type: "goods",
                    }))
                    handleVatInTypeChange()
                  }
                }}
              />
              <Form.Check
                inline
                label="Service"
                name="vat_in_type"
                type="radio"
                checked={formValues.vat_in_type === "service"}
                onClick={(e) => {
                  if (e.target.checked) {
                    setFormValues((prev) => ({
                      ...prev,
                      vat_in_type: "service",
                    }))
                    handleVatInTypeChange()
                  }
                }}
              />
            </Col> */}
            <Col xs={4}>
              <span className="edit-label">
                Supplier Name <label className="badge-required">{` *`}</label>
              </span>
              <AntSelect
                showSearch
                className="w-full mb-1"
                placeholder={
                  formValues.supplier_trade_name ?? "Search Supplier..."
                }
                options={suppliers}
                name="supplier_id"
                value={suppliers.find(
                  (item) => item.value === formValues.supplier_id
                )}
                filterOption={
                  (input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase()) // Filters by label
                }
                onSearch={fetchSuppliers}
                onChange={(e) => {
                  setFormValues((prev) => ({
                    ...prev,
                    supplier_id: e,
                  }))
                }}
              />
              {/* <InputError
                isValid={isError.supplier_id}
                message={"Supplier is required"}
              /> */}
              <div className="d-flex justify-content-end">
                <span
                  className="edit-label smaller-text"
                  style={{ color: "#df1227" }}
                >
                  Supplier Not Found?{" "}
                  <a
                    onClick={handleShowAddSupplierModal}
                    className="add-supplier-label pointer"
                  >
                    Click here to add Supplier
                  </a>
                </span>
              </div>
            </Col>
            {/* <Col xs={4}>
              <span className="edit-label">
                Business Unit<label className="badge-required">{` *`}</label>
              </span>
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                placeholder="Select requisitioner..."
                value={requisitionerValue}
                options={distributors}
                onChange={(e) => handleSelectChange(e)}
              />
              <InputError
                isValid={isError.requisitioner}
                message={"Requisitioner is required"}
              />
            </Col> */}
            <Col xs={4}>
              <span className="edit-label">
                Request Date <label className="badge-required">{` *`}</label>
              </span>
              <DatePicker
                format="YYYY-MM-DD"
                className="nc-modal-custom-text w-100"
                value={formValues.request_date}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    request_date: e,
                  }))
                }
              />
              {/* <InputError
                isValid={isError.request_date}
                message={"Reques date is required"}
              /> */}
            </Col>
            <Col xs={4}>
              <span className="edit-label">Due Date</span>
              <label className="badge-required">{` *`}</label>
              <DatePicker
                format="YYYY-MM-DD"
                className="nc-modal-custom-text w-100"
                value={formValues.due_date}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    due_date: e,
                  }))
                }
              />
              {/* <InputError
                isValid={isError.due_date}
                message={"Due date is required"}
              /> */}
            </Col>
          </Row>

          {/* <Row className="mt-4"> */}
          {/* <Col xs={4}>
              <span className="edit-label">Due Date</span>
              <label className="badge-required">{` *`}</label>
              <DatePicker
                format="YYYY-MM-DD"
                className="nc-modal-custom-text w-100"
                value={formValues.due_date}
                onChange={(e) =>
                  setFormValues((prev) => ({
                    ...prev,
                    due_date: e,
                  }))
                }
              />
              <InputError
                isValid={isError.due_date}
                message={"Due date is required"}
              />
            </Col> */}
          {/* </Row>   */}
          <Row className="mt-4">
            <Col xs={12}>
              <span className="edit-label">
                Remarks
                <span className="edit-optional px-2">(Optional)</span>
              </span>
              <Form.Control
                className="nc-modal-custom-input"
                as="textarea"
                name="remark"
                value={formValues.remark}
                onChange={(e) => handleSEChange(e)}
              />
            </Col>
          </Row>

          <Row className="mt-4 mb-2">
            <span className="edit-label">File Attachments</span>
            <Col xs={12}>
              <Dragger
                {...{
                  // style: { width: 550 },
                  fileAttachments,
                  fileList: fileAttachments,
                  onRemove: handleRemove,
                  beforeUpload: handleBeforeUpload,
                  multiple: true,
                  // onChange: handleOnChange,
                  // listType: "picture",
                  progress: { showInfo: true },
                }}
              >
                <img src={upload} className="cursor-pointer" alt="" />
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
              </Dragger>
            </Col>
          </Row>

          <Row className="mt-4 pt-3">
            <span className="edit-label mb-2">
              Purchased Items
              <label className="badge-required">{` *`}</label>
            </span>
          </Row>
          <Row>
            <div>
              {purchasedItems !== undefined && purchasedItems.length === 0 ? (
                <span>No Purchased Item Found!</span>
              ) : (
                <>
                  <div className="purchased-items-table-container">
                    <Table className="purchased-items-table project-pi-table">
                      <thead>
                        <tr>
                          <th>VAT Type</th>
                          <th>Account Type</th>
                          <th>Price</th>
                          <th>VAT</th>
                          <th>WHT (%)</th>
                          <th>WHT Amt</th>
                          <th>Grand Total</th>
                          <th>project Name</th>
                          <th>Class</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {purchasedItems !== undefined &&
                          purchasedItems.map((item, index) => {
                            return (
                              <tr key={item.id}>
                                <td style={{ width: "15%" }}>
                                  <AntSelect
                                    placeholder="SELECT TYPE"
                                    name="vat_type"
                                    value={item.vat_type}
                                    options={vatTypeOptions}
                                    onChange={(e) => {
                                      if (e !== undefined) {
                                        handleItemChange("vat_type", e, index)
                                      } else {
                                        handleItemChange("vat_type", "", index)
                                      }
                                    }}
                                  />
                                </td>
                                <td style={{ width: "15%" }}>
                                  <AntSelect
                                    // options={expenses}
                                    showSearch
                                    optionFilterProp="label"
                                    options={ceNumbers.map((ce) => ({
                                      value: ce.value,
                                      label: ce.label,
                                    }))}
                                    value={item.expense_type_id}
                                    name="expense_type_id"
                                    onChange={(e) =>
                                      handleItemChange(
                                        "expense_type_id",
                                        e,
                                        index
                                      )
                                    }
                                    dropdownStyle={{ overflow: "hidden" }}
                                  />
                                </td>
                                <td style={{ width: "8%" }}>
                                  <Form.Control
                                    name="price"
                                    value={item.price}
                                    onChange={(e) =>
                                      handleItemChange(
                                        "price",
                                        e.target.value,
                                        index
                                      )
                                    }
                                  />
                                </td>
                                <td style={{ width: "8%" }}>
                                  <Form.Control
                                    name="vat_in"
                                    value={formatAmount(item.vat_in_display)}
                                    disabled
                                  />
                                </td>
                                <td
                                  style={{ width: "15%" }}
                                  className={`${
                                    formValues.vat_type === "zero_rated"
                                      ? "hidden"
                                      : ""
                                  }`}
                                >
                                  <AntSelect
                                    placeholder="SELECT %"
                                    allowClear
                                    name="wht_percent"
                                    value={
                                      item.vat_type === "vat_in_goods" ||
                                      item.vat_type === "vat_ex_goods"
                                        ? whtOptionsGoods.find((option) =>
                                            option.value.includes(
                                              item.wht_percent_code
                                            )
                                          )?.value
                                        : item.vat_type === "vat_in_service" ||
                                          item.vat_type === "vat_ex_service"
                                        ? whtOptionsService.find((option) =>
                                            option.value.includes(
                                              item.wht_percent_code
                                            )
                                          )?.value
                                        : null // Default to null if no conditions are met
                                    }
                                    disabled={item.vat_type === "non_vat"}
                                    options={
                                      item.vat_type === "vat_in_goods" ||
                                      item.vat_type === "vat_ex_goods"
                                        ? whtOptionsGoods
                                        : whtOptionsService
                                    }
                                    onChange={(e) => {
                                      if (e !== undefined) {
                                        const wht = e.split("_")[1]
                                        const whtCode = e.split("_")[0]
                                        handleItemChange(
                                          "wht_percent",
                                          wht,
                                          index
                                        )
                                        handleItemChange(
                                          "wht_percent_code",
                                          whtCode,
                                          index
                                        )
                                      } else {
                                        handleItemChange(
                                          "wht_percent",
                                          0,
                                          index
                                        )
                                        handleItemChange(
                                          "wht_percent_code",
                                          0,
                                          index
                                        )
                                      }
                                    }}
                                  />
                                </td>
                                <td
                                  style={{ width: "8%" }}
                                  className={`${
                                    formValues.vat_type === "zero_rated"
                                      ? "hidden"
                                      : ""
                                  }`}
                                >
                                  <Form.Control
                                    name="wht_amount"
                                    value={formatAmount(item.wht_amount)}
                                    disabled
                                  />
                                </td>
                                <td style={{ width: "8%" }}>
                                  {showLoader
                                    ? null
                                    : formatAmount(item.grand_total)}
                                </td>
                                <td style={{ width: "15%" }}>
                                  <div className="d-flex flex-row gap-1 align-items-center">
                                    <AntSelect
                                      options={projects}
                                      showSearch
                                      optionFilterProp="label"
                                      value={item.ce_no}
                                      name="class"
                                      onChange={(e) =>
                                        handleItemChange("ce_no", e, index)
                                      }
                                    />
                                    <div
                                      className="cursor-pointer"
                                      onClick={() =>
                                        handleCopy(item.ce_no, projects)
                                      }
                                    >
                                      <ContentCopy
                                        style={{ fontSize: 20, color: "gray" }}
                                      />
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  <div style={{ width: 220 }}>
                                    <AntSelect
                                      options={costCenter.map((cc) => ({
                                        value: cc?.id,
                                        label: cc?.name,
                                      }))}
                                      showSearch
                                      optionFilterProp="label"
                                      value={item.class}
                                      name="class"
                                      onChange={(e) =>
                                        handleItemChange("class", e, index)
                                      }
                                      dropdownRender={(menu) => (
                                        <div>
                                          {menu}
                                          <Divider
                                            style={{ margin: "8px 0" }}
                                          />
                                          <Space
                                            style={{
                                              padding: "0 8px 4px",
                                            }}
                                          >
                                            <Input
                                              value={customClass}
                                              placeholder="Please enter class"
                                              onChange={(e) =>
                                                setCustomClass(e.target.value)
                                              }
                                              onKeyDown={(e) =>
                                                e.stopPropagation()
                                              }
                                            />
                                            <Button
                                              type="text"
                                              icon={<PlusOneOutlined />}
                                              onClick={async () =>
                                                handleAddCustomClass()
                                              }
                                            >
                                              Add
                                            </Button>
                                          </Space>
                                        </div>
                                      )}
                                    />
                                  </div>
                                </td>
                                <td style={{ width: "5%" }}>
                                  <img
                                    src={trash}
                                    onClick={() => handleRemoveItem(index)}
                                    className="cursor-pointer p-0 m-0 ms-3"
                                    alt=""
                                  />
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </Table>
                  </div>
                </>
              )}
              <Row className="mt-3">
                <Col span={2}>
                  <button className="button-primary" onClick={() => AddItem()}>
                    Add Item
                  </button>
                </Col>
              </Row>
              <Row className="mt-5">
                <Col
                  xs={9}
                  className="print-table-footer-label grand-label d-flex justify-content-end"
                >
                  SUBTOTAL
                </Col>
                <Col
                  xs={3}
                  className="print-table-footer-label grand-label d-flex justify-content-end"
                >
                  {formatAmount(totalSummary.subtotal)}
                </Col>
              </Row>
              {/* <Row
                className={`mt-3`}
                style={{
                  display: formValues.vat_type === "zero_rated" ? "none" : "",
                }}
              >
                <Col
                  xs={9}
                  className={`print-table-footer-label grand-label d-flex justify-content-end`}
                >
                  WHT %
                </Col>
                <Col
                  xs={3}
                  className="print-table-footer-label grand-label d-flex justify-content-end"
                >
                  <AntSelect
                    placeholder="SELECT %"
                    className="w-90"
                    value={
                      formValues.vat_in_type === "goods"
                        ? whtOptionsGoods.find((option) =>
                            option.value.includes(
                              totalSummary.wht_percent_value
                            )
                          )
                        : whtOptionsService.find((option) =>
                            option.value.includes(
                              totalSummary.wht_percent_value
                            )
                          )
                    }
                    options={
                      formValues.vat_in_type === "goods"
                        ? whtOptionsGoods
                        : whtOptionsService
                    }
                    disabled={
                      totalSummary.particulars_wht > 0 &&
                      (totalSummary.wht_percent_value === undefined ||
                        totalSummary.wht_percent_value === null)
                    }
                    onChange={(e) => {
                      const wht = e?.split("_")[1] || 0
                      const whtValue = e?.split("_")[0]
                      setTotalSummary((prev) => ({
                        ...prev,
                        wht_percent: wht,
                        wht_percent_value: e,
                        wht_percent_code: whtValue,
                        disable_wht_per_item: true,
                      }))

                      setPurchasedItems((prevItems) => {
                        return prevItems.map((item, index) => {
                          return {
                            ...item,
                            wht_percent: undefined,
                            wht_percent_code: undefined,
                          }
                        })
                      })
                    }}
                  />
                </Col>
              </Row> */}
              <Row className="mt-3">
                <Col
                  xs={9}
                  className="print-table-footer-label grand-label d-flex justify-content-end"
                >
                  12% VAT
                </Col>
                <Col
                  xs={3}
                  className="print-table-footer-label grand-label d-flex justify-content-end"
                >
                  {formatAmount(totalSummary.vat_in_total)}
                </Col>
              </Row>
              <Row
                className="mt-3"
                style={{
                  display: formValues.vat_type === "zero_rated" ? "none" : "",
                }}
              >
                <Col
                  xs={9}
                  className="print-table-footer-label grand-label d-flex justify-content-end"
                >
                  WHT AMOUNT
                </Col>
                <Col
                  xs={3}
                  className="print-table-footer-label grand-label d-flex justify-content-end"
                >
                  {formatAmount(totalSummary.wht_amount)}
                </Col>
              </Row>

              <Row className="mt-3">
                <Col
                  xs={9}
                  className="print-table-footer-label grand-label d-flex justify-content-end"
                >
                  <h5 className="bold">GRAND TOTAL</h5>
                </Col>
                <Col
                  xs={3}
                  className="print-table-footer-label grand-label d-flex justify-content-end"
                >
                  <h5 className="bold">
                    {formatAmount(totalSummary.grand_total)}
                  </h5>
                </Col>
              </Row>
            </div>
          </Row>

          <div className="d-flex justify-content-end pt-5 pb-3">
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate("/projectpurchaseinvoice")}
            >
              Close
            </button>
            {!isSubmitClicked && (
              <button
                type="button"
                className="button-primary"
                onClick={() => {
                  formValues["is_save"] = 0
                  handleSubmit()
                }}
                // disabled={!submittableForm || isNaN(totalSummary.grand_total)}
              >
                {type === "for_approval" ? "Done" : "Submit"}
              </button>
            )}
            {isSubmitClicked && (
              <div className="button-primary d-flex justify-content-center">
                <ReactLoading
                  type="bubbles"
                  color="#FFFFFF"
                  height={50}
                  width={50}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Add SUpplier Modal */}
      <AddModal
        title="SUPPLIER"
        show={showAddSupplierModal}
        onHide={handleCloseAddSupplierModal}
        onSave={handleAddSupplier}
        disableSave={
          !supplierDetails.trade_name ||
          supplierDetails?.trade_name?.trim() === ""
        }
        isClicked={addVendorIsSaveClicked}
      >
        <div className="mt-3 ">
          <Row className="nc-modal-custom-row mt-0">
            <Col xs={6}>
              BIR NAME
              <Form.Control
                type="text"
                name="bir_name"
                value={supplierDetails.bir_name}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              TRADE NAME <label className="badge-required">{` *`}</label>
              <Form.Control
                type="text"
                name="trade_name"
                value={supplierDetails.trade_name}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
                required
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={6}>
              BIR-REGISTERED ADDRESS
              <Form.Control
                type="text"
                name="bir_address"
                value={supplierDetails.bir_address}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={6}>
              TRADE ADDRESS
              <Form.Control
                type="text"
                name="trade_address"
                value={supplierDetails.trade_address}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              TIN NUMBER{" "}
              <Form.Control
                type="text"
                name="tin"
                value={supplierDetails.tin}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              BIR NUMBER
              <Form.Control
                type="text"
                name="bir_number"
                className="nc-modal-custom-input"
                value={supplierDetails.bir_number}
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4} className="nc-modal-custom-row-details">
              TERMS (NO. OF DAYS)
              <Form.Control
                type="text"
                name="terms"
                value={supplierDetails.terms}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              CONTACT PERSON
              <Form.Control
                type="text"
                name="contact_person"
                value={supplierDetails.contact_person}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              PHONE NUMBER
              <Form.Control
                type="text"
                name="phone_no"
                value={supplierDetails.phone_no}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              PAYEE
              <Form.Control
                type="email"
                name="payee"
                value={supplierDetails.payee}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
                required
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              PRIMARY BANK NAME
              <Form.Control
                type="text"
                name="bank_primary"
                value={supplierDetails.bank_primary}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NO.
              <Form.Control
                type="number"
                onWheel={(e) => e.target.blur()}
                name="primary_account_no"
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              PRIMARY ACCOUNT NAME
              <Form.Control
                type="text"
                name="primary_account_name"
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              ALTERNATE BANK NAME
              <Form.Control
                type="text"
                name="bank_alternate"
                value={supplierDetails.bank_alternate}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NO.
              <Form.Control
                type="number"
                onWheel={(e) => e.target.blur()}
                name="alternate_account_no"
                className="nc-modal-custom-input"
                value={supplierDetails.alternate_account_no}
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
            <Col xs={4}>
              ALTERNATE ACCOUNT NAME
              <Form.Control
                type="text"
                name="alternate_account_name"
                className="nc-modal-custom-input"
                value={supplierDetails.alternate_account_name}
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col xs={4}>
              COMPANY EMAIL
              <Form.Control
                type="email"
                name="email"
                value={supplierDetails.email}
                className="nc-modal-custom-input"
                onChange={(e) =>
                  setSupplierDetails({
                    ...supplierDetails,
                    [e.target.name]: e.target.value,
                  })
                }
              />
            </Col>
          </Row>
        </div>
      </AddModal>
    </div>
  )
}

FormOperationalExpenses.defaultProps = {
  add: false,
  edit: false,
  defaultValues: {},
}

export default FormOperationalExpenses
