import React, { useContext, useEffect } from "react"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import { Navigate } from "react-router-dom"
import { Toaster } from "react-hot-toast"
import { toastStyle } from "./Helpers/Utils/Common"
import { getToken, getType } from "./Helpers/Utils/Common"

import "./Components/FontAwesomeIcons"
import "./Assets/Images/FontAwesomeIcons"
import "./App.css"
import "./Helpers/Utils/Common.css"

import Login from "./Pages/Login/Login"
import Dashboard3 from "./Pages/Dashboard/Dashboard3"

// BILLING
import BillingSalesInvoice from "./Pages/BillingSalesInvoice/BillingSalesInvoice"
import FormBillingSalesInvoice from "./Pages/BillingSalesInvoice/FormBillingSalesInvoice"
import PrintBillingSalesInvoice from "./Pages/BillingSalesInvoice/PrintBillingSalesInvoice"

// FINANCIAL STATEMENTS
import IncomeStatementReport from "./Pages/FinancialReport/IncomeStatementReport"
import BalanceSheet from "./Pages/FinancialReport/BalanceSheet"
import GeneralLedger from "./Pages/GeneralLedger/GeneralLedger"
import GeneralLedgerForm from "./Pages/GeneralLedger/GeneralLedgerForm"
import AccountsReceivable from "./Pages/FinancialReport/AccountsReceivable"

// OPERATING EXPENSES
import OperationalExpenses from "./Pages/OperationalExpenses/OperationalExpenses"
// import FormOperationalExpenses from "./Pages/OperationalExpenses/Components/FormOperationalExpenses";
// import ReviewOperationalExpense from "./Pages/OperationalExpenses/Components/ReviewOperationalExpense";
// import PrintOperationalExpense from "./Pages/OperationalExpenses/Components/PrintOperationalExpense";

// PROJECT EXPENSES
import ProjectPurchaseInvoice from "./Pages/ProjectPurchaseInvoice/ProjectPurchaseInvoice"
import PrintProjectPurchaseInvoice from "./Pages/ProjectPurchaseInvoice/Components/PrintProjectPurchaseInvoice"
import FormProjectPurchaseInvoice from "./Pages/ProjectPurchaseInvoice/Components/FormProjectPurchaseInvoice"
import ReviewProjectPurchaseInvoice from "./Pages/ProjectPurchaseInvoice/Components/ReviewProjectPurchaseInvoice"
import Project from "./Pages/Project/Project"
import FormProject from "./Pages/Project/FormProject"
import PrintProject from "./Pages/Project/PrintProject"

// OTHERS
import JournalEntry from "./Pages/JournalEntry/JournalEntry"
import JournalEntryForm from "./Pages/JournalEntry/JournalEntryForm"

// MANAGE
import Distributor from "./Pages/Distributors/Distributor"
import DistributorForm from "./Pages/Distributors/DistributorForm"
import PrintDistributor from "./Pages/Distributors/PrintDistributor"
import DistributorGenerateBilling from "./Pages/Distributors/DistributorGenerateBilling"
import Suppliers from "./Pages/Manage/Suppliers"
import Customer from "./Pages/Manage/Customer"
import ExpenseTypes from "./Pages/Manage/ExpenseTypes"
import CostCenter from "./Pages/Manage/CostCenter"
import Banks from "./Pages/Banks/Banks"
import Users from "./Pages/Users/Users"
import LandingPage from "./Pages/LandingPage/LandingPage"
import { Context } from "./Helpers/Context/Context"
import TrialBalance from "./Pages/TrialBalance/TrialBalance"
import Classifications from "./Pages/ManageItems/Classifications"
import ItemsList from "./Pages/ManageItems/ItemsList"
import StockIn from "./Pages/StockIn/StockIn"
import StockOut from "./Pages/StockOut/StockOut"
import WarehouseManager from "./Pages/Warehouse/WarehouseManager"
import WarehouseList from "./Pages/Warehouse/WarehouseList"
import BuPerWarehouse from "./Pages/Warehouse/BuPerWarehouse"
import ItemHistory from "./Pages/ItemHistory/ItemHistory"

function App() {
  document.title = "WISHCRAFT"
  document.body.style = "background: #F8F7F7;"

  const token = getToken()
  const type = getType()

  const { primaryColor } = useContext(Context)

  useEffect(() => {
    // Apply the stored primary color on app load
    if (primaryColor) {
      document.documentElement.style.setProperty(
        "--primary-color",
        primaryColor
      )
      document.documentElement.style.setProperty(
        "--secondary-color",
        primaryColor
      )
    }
  }, [primaryColor])

  return (
    <div className="page">
      <Toaster
        position="top-right"
        reverseOrder={false}
        toastOptions={{ style: { toastStyle } }}
      />
      {/* <BuProvider> */}
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/dashboard"
            element={token ? <Dashboard3 /> : <Login />}
          />
          <Route
            path="/landing-page"
            element={token ? <LandingPage /> : <Login />}
          />
          {/* BILLING SALES INVOICE */}
          <Route
            path="/billingsalesinvoice"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head" ||
                type === "finance_officer") ? (
                <BillingSalesInvoice />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/billingsalesinvoice/add"
            element={
              token &&
              (type === "admin" ||
                type === "finance_officer" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <FormBillingSalesInvoice add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/billingsalesinvoice/edit/:id"
            element={
              token &&
              (type === "admin" ||
                type === "finance_officer" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <FormBillingSalesInvoice edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/billingsalesinvoice/print/:id"
            element={
              token &&
              (type === "admin" ||
                type === "finance_officer" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <PrintBillingSalesInvoice />
              ) : (
                <Login />
              )
            }
          />
          {/* FINANCIAL STATEMENTS */}
          <Route
            path="/incomestatementreport"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <IncomeStatementReport />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/balancesheet"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <BalanceSheet />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/generalledger"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "finance_officer" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <GeneralLedger />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/generalledger/view"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <GeneralLedgerForm view />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/generalledger/add"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <GeneralLedgerForm add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/generalledger/edit"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <GeneralLedgerForm edit />
              ) : (
                <Login />
              )
            }
          />
          {/* PROJECT EXPENSE */}
          <Route
            path="/projectfolder"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_manager" ||
                type === "requester" ||
                type === "business_unit_finance_head") ? (
                <Project />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/accountsreceivable"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <AccountsReceivable />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/trialbalance"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <TrialBalance />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/warehouse-classifications"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <Classifications />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/items-list"
            element={
              token &&
              (type === "admin" ||
                type === "warehouse_man" ||
                type === "receiver_releaser" ||
                type === "warehouse_requester") ? (
                <ItemsList />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/item-history"
            element={
              token &&
              (type === "admin" ||
                type === "warehouse_man" ||
                type === "receiver_releaser") ? (
                <ItemHistory />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/stock-in"
            element={
              token &&
              (type === "admin" ||
                type === "warehouse_man" ||
                type === "warehouse_requester" ||
                type === "receiver_releaser") ? (
                <StockIn />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/stock-out"
            element={
              token &&
              (type === "admin" ||
                type === "warehouse_man" ||
                type === "warehouse_requester" ||
                type === "receiver_releaser") ? (
                <StockOut />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projectfolder/add"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <FormProject add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projectfolder/edit/:id"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <FormProject edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projectfolder/renew/:id"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <FormProject renew />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projectfolder/print/:id"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_manager" ||
                type === "business_unit_finance_head") ? (
                <PrintProject />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projectpurchaseinvoice"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager" ||
                type === "finance_officer" ||
                type === "requester") ? (
                <ProjectPurchaseInvoice />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projectpurchaseinvoice/add"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager" ||
                type === "finance_officer" ||
                type === "requester") ? (
                <FormProjectPurchaseInvoice add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projectpurchaseinvoice/edit/:type/:id"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager" ||
                type === "finance_officer" ||
                type === "requester") ? (
                <FormProjectPurchaseInvoice edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/projectpurchaseinvoice/print/:id"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager" ||
                type === "finance_officer" ||
                type === "requester") ? (
                <PrintProjectPurchaseInvoice />
              ) : (
                <Navigate to="/projectpurchaseinvoice" />
              )
            }
          />
          <Route
            path="/projectpurchaseinvoice/review/:id"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager" ||
                type === "finance_officer" ||
                type === "requester") ? (
                <ReviewProjectPurchaseInvoice />
              ) : (
                <Navigate to="/projectpurchaseinvoice" />
              )
            }
          />
          {/* OTHERS */}
          <Route
            path="/journalentry"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager" ||
                type === "finance_officer") ? (
                <JournalEntry />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/journalentry/view"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "finance_officer" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager") ? (
                <JournalEntryForm view />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/journalentry/add"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "finance_officer" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager") ? (
                <JournalEntryForm add />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/journalentry/edit"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "finance_officer" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager") ? (
                <JournalEntryForm edit />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/journalentry/replicate"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "finance_officer" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager") ? (
                <JournalEntryForm replicate />
              ) : (
                <Login />
              )
            }
          />

          {/* MANAGE */}
          <Route
            path="/accountcharts"
            element={
              token &&
              (type === "admin" || type === "business_unit_finance_head") ? (
                <ExpenseTypes />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/customers"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager" ||
                type === "finance_1" ||
                type === "finance_2" ||
                type === "requester" ||
                type === "hr_officer") ? (
                <Customer />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/suppliers"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager" ||
                type === "requester" ||
                type === "finance_officer") ? (
                <Suppliers />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/businessunits"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager" ||
                type === "finance_1" ||
                type === "finance_2" ||
                type === "requester" ||
                type === "purchasing_officer") ? (
                <Distributor />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/businessunits/form"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager" ||
                type === "finance_1" ||
                type === "finance_2" ||
                type === "requester" ||
                type === "purchasing_officer") ? (
                <DistributorForm />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/businessunits/print/:id"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager" ||
                type === "finance_1" ||
                type === "finance_2" ||
                type === "requester" ||
                type === "purchasing_officer") ? (
                <PrintDistributor />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/businessunits/billing_statement"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_head" ||
                type === "business_unit_director" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager" ||
                type === "finance_1" ||
                type === "finance_2" ||
                type === "requester" ||
                type === "purchasing_officer") ? (
                <DistributorGenerateBilling />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/costcenters"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager") ? (
                <CostCenter />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/banks"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager") ? (
                <Banks />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/users"
            element={
              token &&
              (type === "admin" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager") ? (
                <Users />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/warehouse"
            element={
              token &&
              (type === "admin" ||
                type === "warehouse_man" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager" ||
                type === "warehouse_requester") ? (
                <WarehouseManager />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/warehouse-list"
            element={
              token &&
              (type === "admin" ||
                type === "warehouse_man" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager" ||
                type === "warehouse_requester" ||
                type === "receiver_releaser") ? (
                <WarehouseList />
              ) : (
                <Login />
              )
            }
          />
          <Route
            path="/business-units"
            element={
              token &&
              (type === "admin" ||
                type === "warehouse_man" ||
                type === "business_unit_finance_head" ||
                type === "business_unit_finance_manager" ||
                type === "warehouse_requester" ||
                type === "receiver_releaser") ? (
                <BuPerWarehouse />
              ) : (
                <Login />
              )
            }
          />
        </Routes>
      </Router>
      {/* </BuProvider> */}
    </div>
  )
}

export default App
