import React, { useContext, useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import Select from "react-select"
import { Select as AntSelect } from "antd"
import {
  generateId,
  getType,
  numberFormatWithoutDecimal,
  refreshPage,
  selectDropdownStyle,
  showWarningToast,
  toastStyle,
} from "../../Helpers/Utils/Common"
import Table from "../../Components/TableTemplate/DataTable"
import { Table as ReactTable } from "react-bootstrap"
import trash from "../../Assets/Images/trash.png"
import downloadIcon from "../../Assets/Images/download_icon.png"

// Components
import Navbar from "../../Components/Navbar/Navbar"
import AddModal from "../../Components/Modals/AddModal"
import EditModal from "../../Components/Modals/EditModal"
import ViewModal from "../../Components/Modals/ViewModal"
import DeleteModal from "../../Components/Modals/DeleteModal"
import { getDistributor } from "../../Helpers/apiCalls/DistributorApi"

//API CALL HELPERS
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import toast from "react-hot-toast"
import { Context } from "../../Helpers/Context/Context"

const ItemsList = () => {
  const [inactive, setInactive] = useState(true)
  const [showLoader, setShowLoader] = useState(false)
  const [selectedRow, setSelectedRow] = useState({})
  const [showModal, setShowModal] = useState("")
  const [details, setDetails] = useState({
    details: "",
  })
  const { warehouseId, selectedBuId, warehouseName } = useContext(Context)
  const [submittableForm, setSubmittableForm] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const [businessUnits, setBusinesUnits] = useState([])
  const [tableData, setTableData] = useState([])
  const [itemOptions, setItemOptions] = useState([])
  const [selectedItems, setSelectedItems] = useState([])
  const filteredOptions = itemOptions.filter(
    (option) => !selectedItems.includes(option.value)
  )
  const [exportToExcelLink, setExportToExcelLink] = useState("")
  const [warehouses, setWarehouses] = useState([])
  const [items, setItems] = useState([
    {
      id: generateId(),
      item_id: "",
      quantity: 0,
      item_remarks: "",
    },
  ])

  const [filterConfig, setFilterConfig] = useState({
    warehouse_id: warehouseName === "general warehouse" ? "" : warehouseId,
    business_unit_id: warehouseName === "general warehouse" ? "" : selectedBuId,
  })

  const warehouseHeaders = [
    "ITEM CODE",
    "ITEM NAME",
    "CLASSIFICATION",
    "DETAILS",
    "BEGINNING INVENTORY",
    "CURRENT INVENTORY",
  ]
  const genWhHeaders = [
    "ITEM CODE",
    "ITEM NAME",
    "CLASSIFICATION",
    "DETAILS",
    "BUSINESS UNIT",
    "WAREHOUSE",
    "BEGINNING INVENTORY",
    "CURRENT INVENTORY",
  ]

  const warehouseSelectors = [
    "item_code",
    "name",
    "classification_name",
    "beginning_inventory",
    "current_inventory",
    "details",
  ]

  const genWhSelectors = [
    "item_code",
    "name",
    "classification_name",
    "business_unit_name",
    "warehouse_name",
    "beginning_inventory",
    "current_inventory",
    "details",
  ]

  const [classificationOptions, setClassificationOptions] = useState([
    { label: "Test", value: "test", for: "classification" },
  ])

  function validate(formDetails) {
    const { name, classification_id, beginning_inventory } = formDetails

    const isFormValid =
      name &&
      classification_id &&
      beginning_inventory &&
      beginning_inventory > 0

    setSubmittableForm(isFormValid)
  }

  function validateStockIn(formDetails) {
    // Function to check if a value is empty, undefined, or null
    const isEmpty = (value) =>
      value === undefined || value === null || value === ""

    // Validate items array
    const areItemsValid = items.every(
      (item) =>
        !isEmpty(item.item_id) && !isEmpty(item.quantity) && item.quantity > 0
    )

    // Validate returnee in details object
    const isDetailsValid = !isEmpty(details.returnee)

    setSubmittableForm(areItemsValid && isDetailsValid)
  }

  function validateStockOut(formDetails) {
    // Function to check if a value is empty, undefined, or null
    const isEmpty = (value) =>
      value === undefined || value === null || value === ""

    // Validate items array
    const areItemsValid = items.every(
      (item) =>
        !isEmpty(item.item_id) && !isEmpty(item.quantity) && item.quantity > 0
    )

    // Validate returnee in details object
    const isDetailsValid = !isEmpty(details.requisitioner)

    setSubmittableForm(areItemsValid && isDetailsValid)
  }

  // function search() {
  // Add your search functionality here
  // }

  async function handleDelete() {
    setIsClicked(true)
    const response = await UsePostRequest(
      "items/delete",
      {
        item_id: selectedRow.id,
        warehouse_id: warehouseId,
      },
      false
    )

    //display any errors
    if (response.error) {
      toast.error("Something went wrong", {
        style: toastStyle(),
      })
      return
    }

    if (response) {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    } else {
      setIsClicked(false)
      toast.success(response.error.messages.message, {
        style: toastStyle(),
      })
    }
  }

  async function handleCreate() {
    setIsClicked(true)
    const payload = {
      ...details,
      warehouse_id: warehouseId,
      business_unit_id: selectedBuId,
    }
    const response = await UsePostRequest("items/create", payload, false)

    if (response.error) {
      if (
        response.error.response.data.messages.status?.code &&
        response.error.response.data.messages.status?.code === 1062
      ) {
        setIsClicked(false)
        toast.error(`Item "${details.name}" already exists.`, {
          style: toastStyle(),
        })
      } else {
        toast.error(`Something went wrong.`, {
          style: toastStyle(),
        })
      }
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
  }

  async function handleUpdate() {
    setIsClicked(true)
    const payload = {
      item_id: details.id,
      name: details.name,
      details: details.details,
      beginning_inventory: details.beginning_inventory,
      classification_id: details.classification_id,
    }
    const response = await UsePostRequest(
      "items/update",
      {
        ...payload,
      },
      false
    )

    if (response.error) {
      if (
        response.error.response.data.messages.status?.code &&
        response.error.response.data.messages.status?.code === 1062
      ) {
        setIsClicked(false)
        toast.error(`Classification "${details.name}" already exists.`, {
          style: toastStyle(),
        })
      } else {
        toast.error(`Something went wrong.`, {
          style: toastStyle(),
        })
      }
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
  }

  async function handleCreateStockIn() {
    setIsClicked(true)

    const payload = {
      ...details,
      warehouse_id: warehouseId,
      business_unit_id: selectedBuId,
      item_id: items.map((item) => item.item_id),
      quantity: items.map((item) => item.quantity),
      remark_item: items.map((item) => item.item_remarks),
    }
    const response = await UsePostRequest("stock_ins/create", payload, false)

    if (response.error) {
      toast.error(`Something went wrong.`, {
        style: toastStyle(),
      })
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
    setIsClicked(false)
  }

  async function handleCreateStockOut() {
    setIsClicked(true)
    const payload = {
      ...details,
      warehouse_id: warehouseId,
      business_unit_id: selectedBuId,
      item_id: items.map((item) => item.item_id),
      quantity: items.map((item) => item.quantity),
      remark_item: items.map((item) => item.item_remarks),
    }
    const response = await UsePostRequest("stock_outs/create", payload, false)

    if (response.error) {
      toast.error(`Something went wrong.`, {
        style: toastStyle(),
      })
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
    setIsClicked(false)
  }

  function handleSelectOption(option) {
    if (option === "add") {
      setShowModal("add")
    } else if (option === "stock-in") {
      setShowModal("stock-in")
    } else if (option === "pull-out") {
      setShowModal("pull-out")
    }
  }

  function handleChange(e, fieldType) {
    const { name, value } = e.target || {}
    let updatedDetails = {}

    if (fieldType === "input") {
      updatedDetails = { ...details, [name]: value }
    } else if (fieldType === "dropdown") {
      updatedDetails = { ...details, [e.for]: e.value }
    }

    setDetails(updatedDetails)
  }

  async function fetchBusinessUnits() {
    try {
      const response = await getDistributor({ name: "" })
      if (response.data && Array.isArray(response.data.data)) {
        let data = response.data.data.map((bu) => {
          let info = bu
          info.for = "business_unit_id"
          info.label = bu.name
          info.value = bu.id
          return info
        })
        setBusinesUnits(data)
      } else {
        setBusinesUnits([])
      }
    } catch (error) {
      setBusinesUnits([])
    }
  }

  async function fetchClassifications() {
    const response = await UseGetRequest(
      "classifications/get_all_last_classification"
    )

    if (response.data) {
      const allSubtypes = response.data.data.reduce((acc, item) => {
        if (item.last_types && Array.isArray(item.last_types)) {
          const subtypes = item.last_types.reduce((innerAcc, subtypeItem) => {
            if (subtypeItem.name) {
              innerAcc.push({
                for: "classification_id",
                label: subtypeItem.name,
                value: subtypeItem.id,
              })
            }
            return innerAcc
          }, [])
          return acc.concat(subtypes)
        }
        return acc
      }, [])
      setClassificationOptions(allSubtypes)
    } else {
      setClassificationOptions([])
    }
  }

  function AddItem() {
    const newItem = {
      id: generateId(),
      item_id: "",
      quantity: 0,
      item_remarks: "",
    }
    setItems((prevItems) => [...prevItems, newItem])
  }

  function handleRemoveItem(index) {
    setItems((prevItems) => {
      const updatedItems = [...prevItems]
      const itemToRemove = updatedItems[index].item_id // Get the item_id of the item being removed

      // Remove the item
      updatedItems.splice(index, 1)
      setItems(updatedItems)

      // Remove item_id from selectedItems
      if (itemToRemove) {
        setSelectedItems((prevSelected) =>
          prevSelected.filter((item) => item !== itemToRemove)
        )
      }

      return updatedItems
    })
  }

  async function fetchTableData() {
    setShowLoader(true)
    const response = await UseGetRequest("items/get_all_item", {
      ...filterConfig,
    })
    if (response.data) {
      const data = response.data.data.map((item) => {
        let info = item
        info.current_inventory =
          item.current_inventory !== "0"
            ? item.current_inventory
            : item.beginning_inventory
        return info
      })

      const items = response.data.data.map((item) => {
        let info = item
        info.for = "item_id"
        info.label = item.name
        info.value = item.id
        info.beginning_inventory = numberFormatWithoutDecimal(
          item.beginning_inventory
        )
        info.current_inventory = numberFormatWithoutDecimal(
          item.current_inventory
        )
        return info
      })
      setItemOptions(items)
      setTableData(data)
    } else {
      setTableData([])
    }
    setShowLoader(false)
  }

  async function fetchWarehouses() {
    const response = await UseGetRequest("warehouses/get_all_warehouse")
    if (response.data.status === "success") {
      const data = response.data.data.map((item) => {
        let info = { ...item }
        info.label = item.name
        info.value = item.id
        return info
      })
      setWarehouses(data)
    }
  }

  async function createExcelFile() {
    const response = await UseGetRequest(`items/create_item_list_excel`, {
      warehouse_id: warehouseId,
      business_unit_id: selectedBuId,
      status: filterConfig.tab,
    })
    if (response.data?.response === "Successful.") {
      setExportToExcelLink(response.data.url)
    }
  }

  useEffect(() => {
    fetchClassifications()
    fetchBusinessUnits()
    fetchWarehouses()
    createExcelFile()
  }, [])

  useEffect(() => {
    fetchTableData()
  }, [filterConfig])

  useEffect(() => {
    if (showModal === "stock-in") {
      validateStockIn(details)
    } else if (showModal === "pull-out") {
      validateStockOut(details)
    } else {
      validate(details)
    }
  }, [details, items, showModal])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => setInactive(inactive)}
          active={"ITEM LIST"}
        />
      </div>
      <div
        className={`manager-container pt-4 ${inactive ? "inactive" : "active"}`}
      >
        <Row className="mb-1 d-flex flex-row gap-2 gap-lg-0">
          <Col xs={12} lg={warehouseName === "general warehouse" ? 7 : 5}>
            <h1 className="page-title m-0 w-full">ITEMS LIST</h1>
          </Col>
          <Col xs={12} lg={3} className="pe-4">
            <input
              type="search"
              name="name"
              placeholder="Search item"
              className="search-bar m-0"
              onChange={(e) => {}}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  fetchTableData(
                    setFilterConfig((prev) => ({
                      ...prev,
                      term: e.target.value,
                    }))
                  )
                }
              }}
            />
          </Col>
          {warehouseName !== "general warehouse" && (
            <Col xs={12} lg={2} className="d-flex justify-content-end">
              <Select
                className="dropsearch-filter px-0 py-0 me-2 w-full"
                classNamePrefix="react-select"
                placeholder="Select"
                styles={selectDropdownStyle}
                onChange={(e) => handleSelectOption(e.value)}
                options={
                  warehouseName === "general warehouse"
                    ? [{ value: "add", label: "Add Item" }]
                    : getType() === "warehouse_requester" ||
                      getType() === "receiver_releaser"
                    ? [
                        { value: "stock-in", label: "Stock In" },
                        { value: "pull-out", label: "Pull Out" },
                      ]
                    : [
                        { value: "add", label: "Add Item" },
                        { value: "stock-in", label: "Stock In" },
                        { value: "pull-out", label: "Pull Out" },
                      ]
                }
              />
            </Col>
          )}
          <Col xs={12} lg={2} className="d-flex justify-content-end">
            <div className="button-primary py-2 w-full text-center">
              <span className="me-2">
                <img width={20} height={20} src={downloadIcon} alt=""></img>
              </span>
              <a href={exportToExcelLink} className="pointer custom-anchor">
                Export to Excel
              </a>
            </div>
          </Col>
        </Row>
        <Row className="my-3 w-full">
          <Col xs={12} md={3}>
            <Select
              className="dropsearch-filter px-0 py-0 me-2 w-full"
              classNamePrefix="react-select"
              placeholder="Select classification"
              onChange={(e) => {
                fetchTableData(
                  setFilterConfig((prev) => ({
                    ...prev,
                    classification_id: e.value,
                  }))
                )
              }}
              options={[
                {
                  label: "All",
                  value: "",
                },
                ...classificationOptions,
              ]}
            />
          </Col>
          {warehouseName === "general warehouse" && (
            <Col xs={12} md={3}>
              <Select
                className="dropsearch-filter px-0 py-0 me-2 w-full"
                classNamePrefix="react-select"
                placeholder="Select business unit"
                onChange={(e) => {
                  fetchTableData(
                    setFilterConfig((prev) => ({
                      ...prev,
                      business_unit_id: e.value,
                    }))
                  )
                }}
                options={[
                  {
                    label: "All",
                    value: "",
                  },
                  ...businessUnits,
                ]}
              />
            </Col>
          )}
          {warehouseName === "general warehouse" && (
            <Col xs={12} md={3}>
              <Select
                className="dropsearch-filter px-0 py-0 me-2 w-full"
                classNamePrefix="react-select"
                placeholder="Select warehouse"
                onChange={(e) => {
                  fetchTableData(
                    setFilterConfig((prev) => ({
                      ...prev,
                      warehouse_id: e.value,
                    }))
                  )
                }}
                options={[
                  {
                    label: "All",
                    value: "",
                  },
                  ...warehouses,
                ]}
              />
            </Col>
          )}
        </Row>
        <Row className="tab-content ms-1 pt-3">
          <Col>
            <div className="below">
              <Table
                tableHeaders={
                  warehouseName === "general warehouse"
                    ? genWhHeaders
                    : warehouseHeaders
                }
                headerSelector={
                  warehouseName === "general warehouse"
                    ? genWhSelectors
                    : warehouseSelectors
                }
                tableData={tableData}
                showLoader={showLoader}
                handleRowClick={(row) => {
                  if (getType() === "warehouse_man" || getType() === "admin") {
                    setSelectedRow(row)
                    setShowModal("view")
                  }
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <AddModal
        title="ITEM"
        size="xl"
        type="branch"
        show={showModal === "add"}
        onHide={() => setShowModal("")}
        onSave={handleCreate}
        isClicked={isClicked}
        disableSave={!submittableForm}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              NAME<span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleChange(e, "input")}
                required
              />
            </Col>
            <Col>
              CLASSIFICATION<span className="required-icon">*</span>
              <Select
                name="classification"
                className="w-full"
                classNamePrefix="react-select"
                placeholder="Select classification"
                onChange={(e) => handleChange(e, "dropdown")}
                options={classificationOptions}
              />
            </Col>
            <Col>
              BEGINNING INVENTORY<span className="required-icon">*</span>
              <Form.Control
                type="number"
                name="beginning_inventory"
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleChange(e, "input")}
                onWheel={(e) => e.target.blur()}
                onKeyDown={(e) => {
                  if (e.key === "ArrowUp" || e.key === "ArrowDown") {
                    e.preventDefault()
                  }
                }}
                required
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              ITEM DETAILS
              <Form.Control
                type="text"
                name="details"
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleChange(e, "input")}
              />
            </Col>
          </Row>
        </div>
      </AddModal>
      <EditModal
        title="ITEM"
        size="xl"
        type="branch"
        show={showModal === "edit"}
        onHide={() => setShowModal("")}
        onSave={() => handleUpdate()}
        disableSave={!submittableForm}
        isClicked={isClicked}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              NAME<span className="required-icon">*</span>
              <Form.Control
                type="text"
                name="name"
                value={details.name}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleChange(e, "input")}
                required
              />
            </Col>
            <Col>
              CLASSIFICATION<span className="required-icon">*</span>
              <Select
                name="classification"
                className="w-full"
                value={classificationOptions.find(
                  (option) => details.classification_id === option.value
                )}
                classNamePrefix="react-select"
                placeholder="Select classification"
                onChange={(e) => handleChange(e, "dropdown")}
                options={classificationOptions}
              />
            </Col>
            <Col>
              CURRENT INVENTORY<span className="required-icon">*</span>
              <Form.Control
                type="number"
                name="beginning_inventory"
                value={details.current_inventory}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleChange(e, "input")}
                required
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              ITEM DETAILS
              <Form.Control
                type="text"
                name="details"
                value={details.details}
                className="nc-modal-custom-input-edit"
                onChange={(e) => handleChange(e, "input")}
              />
            </Col>
          </Row>
        </div>
      </EditModal>
      <ViewModal
        withButtons
        withHeader={true}
        title="ITEM"
        size="lg"
        show={showModal === "view"}
        onHide={() => setShowModal("")}
        onEdit={() => {
          setDetails(selectedRow)
          setShowModal("edit")
        }}
        onDelete={() => setShowModal("delete")}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              NAME<span className="required-icon">*</span>
              <Form.Control
                type="text"
                value={selectedRow.name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
            <Col>
              CLASSIFICATION<span className="required-icon">*</span>
              <Form.Control
                type="text"
                value={selectedRow.classification_name}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
            <Col>
              CURRENT INVENTORY<span className="required-icon">*</span>
              <Form.Control
                type="number"
                value={selectedRow.current_inventory}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              ITEM DETAILS
              <Form.Control
                type="text"
                value={selectedRow.details}
                className="nc-modal-custom-input-edit"
                disabled
              />
            </Col>
          </Row>
        </div>
      </ViewModal>
      <DeleteModal
        text="item"
        show={showModal === "delete"}
        onHide={() => setShowModal("edit")}
        onDelete={handleDelete}
      />
      <AddModal
        title="STOCK IN"
        size="xl"
        type="branch"
        show={showModal === "stock-in"}
        onHide={() => {
          setShowModal("")
          refreshPage()
        }}
        onSave={handleCreateStockIn}
        isClicked={isClicked}
        disableSave={!submittableForm}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row">
            <Col>
              RETURNEE
              <Form.Control
                name="returnee"
                type="text"
                onChange={(e) => handleChange(e, "input")}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0 px-2 stock-in">
            ITEMS TO STOCK IN
            <div className="purchased-items-table-container">
              <ReactTable>
                <thead>
                  <tr>
                    <th>ITEM</th>
                    <th>QUANTITY</th>
                    <th>REMARKS</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {items !== undefined &&
                    items?.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <AntSelect
                              showSearch
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              name="item_id"
                              className="w-full"
                              classNamePrefix="react-select"
                              placeholder="Select item"
                              onChange={(e) => {
                                // Remove the previously selected item from selectedItems
                                const previousItemId = items[index].item_id // Store previous item_id for reinsertion
                                if (previousItemId) {
                                  setItemOptions((prevOptions) => {
                                    // Check if previousItem is already in itemOptions
                                    const optionToReinsert = itemOptions.find(
                                      (option) =>
                                        option.value === previousItemId
                                    )
                                    if (
                                      optionToReinsert &&
                                      !prevOptions.some(
                                        (option) =>
                                          option.value === previousItemId
                                      )
                                    ) {
                                      return [...prevOptions, optionToReinsert]
                                    }
                                    return prevOptions
                                  })
                                }

                                // Update selectedItems with the new selection
                                setSelectedItems((prev) => [
                                  ...prev.filter(
                                    (item) => item !== previousItemId
                                  ),
                                  e,
                                ])

                                // Update items with the new selected option
                                const updatedItems = [...items]
                                updatedItems[index] = {
                                  ...updatedItems[index],
                                  item_id: e, // Assuming e.value is the new selected item's value
                                }
                                setItems(updatedItems)
                              }}
                              options={filteredOptions}
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                            />
                          </td>
                          <td>
                            <Form.Control
                              name="quantity"
                              type="number"
                              onKeyDown={(e) => {
                                if (
                                  e.key === "ArrowUp" ||
                                  e.key === "ArrowDown"
                                ) {
                                  e.preventDefault()
                                }
                              }}
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => {
                                const updatedItems = [...items]
                                updatedItems[index].quantity = e.target.value
                                setItems(updatedItems)
                              }}
                              className="nc-modal-custom-input-edit"
                            />
                          </td>
                          <td>
                            <Form.Control
                              name="remarks"
                              type="text"
                              onChange={(e) => {
                                const updatedItems = [...items]
                                updatedItems[index].item_remarks =
                                  e.target.value
                                setItems(updatedItems)
                              }}
                              className="nc-modal-custom-input-edit"
                            />
                          </td>
                          <td style={{ width: "5%" }}>
                            <img
                              src={trash}
                              onClick={() => handleRemoveItem(index)}
                              className="cursor-pointer p-0 m-0"
                              alt=""
                            />
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </ReactTable>
            </div>
          </Row>
          <Row className="pt-3 PO-add-item d-flex w-full justify-content-start flex-row">
            <Button type="button" onClick={() => AddItem()}>
              Add Item
            </Button>
          </Row>
        </div>
      </AddModal>
      <AddModal
        title="PULL OUT"
        size="xl"
        type="branch"
        show={showModal === "pull-out"}
        onHide={() => {
          refreshPage()
          setShowModal("")
        }}
        onSave={handleCreateStockOut}
        isClicked={isClicked}
        disableSave={!submittableForm}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              REQUISITIONER<span className="required-icon">*</span>
              <Form.Control
                name="requisitioner"
                onChange={(e) => handleChange(e, "input")}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0 px-2 stock-in">
            ITEMS TO PULL OUT
            <div className="purchased-items-table-container">
              <ReactTable>
                <thead>
                  <tr>
                    <th>ITEM</th>
                    <th>ITEM CODE</th>
                    <th>CURRENT</th>
                    <th>QUANTITY</th>
                    <th>REMARKS</th>
                    <th>ACTION</th>
                  </tr>
                </thead>
                <tbody>
                  {items !== undefined &&
                    items?.map((item, index) => {
                      return (
                        <tr key={item.id}>
                          <td>
                            <AntSelect
                              showSearch
                              filterOption={(input, option) =>
                                (option?.label ?? "")
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              name="item_id"
                              className="w-full"
                              style={{ width: 200 }}
                              classNamePrefix="react-select"
                              placeholder="Select item"
                              onChange={(e) => {
                                // Remove the previously selected item from selectedItems
                                const previousItemId = items[index].item_id // Store previous item_id for reinsertion
                                if (previousItemId) {
                                  setItemOptions((prevOptions) => {
                                    // Check if previousItem is already in itemOptions
                                    const optionToReinsert = itemOptions.find(
                                      (option) =>
                                        option.value === previousItemId
                                    )
                                    if (
                                      optionToReinsert &&
                                      !prevOptions.some(
                                        (option) =>
                                          option.value === previousItemId
                                      )
                                    ) {
                                      return [...prevOptions, optionToReinsert]
                                    }
                                    return prevOptions
                                  })
                                }

                                // Update selectedItems with the new selection
                                setSelectedItems((prev) => [
                                  ...prev.filter(
                                    (item) => item !== previousItemId
                                  ),
                                  e,
                                ])

                                //get the current inventory
                                const selectedOption = filteredOptions.find(
                                  (option) => option.value === e
                                )

                                // Update items with the new selected option
                                const updatedItems = [...items]
                                updatedItems[index] = {
                                  ...updatedItems[index],
                                  item_id: e,
                                  current_inventory:
                                    selectedOption.current_inventory,
                                  item_code: selectedOption.item_code,
                                }
                                setItems(updatedItems)
                              }}
                              options={filteredOptions}
                              getPopupContainer={(trigger) =>
                                trigger.parentNode
                              }
                            />
                          </td>
                          <td>
                            <Form.Control
                              disabled
                              value={item.item_code}
                              className="nc-modal-custom-input-edit"
                            />
                          </td>
                          <td>
                            <Form.Control
                              disabled
                              value={item.current_inventory}
                              className="nc-modal-custom-input-edit"
                            />
                          </td>
                          <td>
                            <Form.Control
                              name="quantity"
                              type="number"
                              onKeyDown={(e) => {
                                if (
                                  e.key === "ArrowUp" ||
                                  e.key === "ArrowDown"
                                ) {
                                  e.preventDefault()
                                }
                              }}
                              onWheel={(e) => e.target.blur()}
                              onChange={(e) => {
                                if (
                                  parseFloat(e.target.value) >
                                  parseFloat(item.current_inventory)
                                ) {
                                  showWarningToast(
                                    `Pull out qty should not exceed the current inventory qty`
                                  )
                                  setSubmittableForm(false)
                                } else {
                                  const updatedItems = [...items]
                                  updatedItems[index].quantity = e.target.value
                                  setItems(updatedItems)
                                }
                              }}
                              className="nc-modal-custom-input-edit"
                            />
                          </td>
                          <td>
                            <Form.Control
                              name="remarks"
                              type="text"
                              onChange={(e) => {
                                const updatedItems = [...items]
                                updatedItems[index].item_remarks =
                                  e.target.value
                                setItems(updatedItems)
                              }}
                              className="nc-modal-custom-input-edit"
                            />
                          </td>
                          <td style={{ width: "5%" }}>
                            <img
                              src={trash}
                              onClick={() => handleRemoveItem(index)}
                              className="cursor-pointer p-0 m-0"
                              alt=""
                            />
                          </td>
                        </tr>
                      )
                    })}
                </tbody>
              </ReactTable>
            </div>
          </Row>
          <Row className="pt-3 PO-add-item d-flex w-full justify-content-start flex-row">
            <Button type="button" onClick={() => AddItem()}>
              Add Item
            </Button>
          </Row>
        </div>
      </AddModal>
    </div>
  )
}

export default ItemsList
