import React, { useContext, useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { Select, DatePicker, Upload } from "antd"
import { useNavigate, useParams } from "react-router-dom"
import toast from "react-hot-toast"
import "react-autocomplete-input/dist/bundle.css"
import "react-bootstrap-typeahead/css/Typeahead.css"
import dayjs from "dayjs"
import {
  getTodayDateISO,
  toastStyle,
  base64ToFile,
  capitalizeFirstLetterOfEachWord,
  getUserId,
  getType,
} from "../../Helpers/Utils/Common"
import Navbar from "../../Components/Navbar/Navbar"
import "./Project.css"
import { Fragment } from "react"
import { useEffect } from "react"
import { getAllCustomer } from "../../Helpers/apiCalls/CustomerAPI"
import { getDistributor } from "../../Helpers/apiCalls/DistributorApi"
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import upload from "../../Assets/Images/upload.png"
import { Context } from "../../Helpers/Context/Context"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"

/**
 *  -- COMPONENT: FORM TO ADD OR EDIT FRANCHISEE SALES INVOICE
 */
const { RangePicker } = DatePicker
const { Dragger } = Upload
function FormProject({ add, edit, renew }) {
  const navigate = useNavigate()
  const { selectedBuId } = useContext(Context)
  const [inactive, setInactive] = useState(true)
  const [isSubmitClicked, setIsSubmitClicked] = useState(false)
  const [ceNumbers, setCeNumbers] = useState([0])
  const [users, setUsers] = useState([])
  const [fileAttachments, setFileAttachments] = useState([])
  /**
   *  @po_id - param for add purchase invoice form
   *  @id - param for edit purchase invoice form
   */
  const { id } = useParams()
  const today = getTodayDateISO()

  // FRANCHISEE INVOICE DETAILS HANDLER
  const [formValues, setFormValues] = useState({ status: "open" })

  // DATA HANDLERS
  const [customersData, setCustomersData] = useState([])
  const [customerInfo, setCustomerInfo] = useState([])
  const [distributors, setDistributors] = useState([])
  const [submittableForm, setSubmittableForm] = useState(false)

  async function fetchDistributors() {
    const response = await getDistributor("")
    if (response.data) {
      const res = response.data.data.map((row) => {
        var info = row
        info.label = row.name
        info.value = row.id
        return info
      })
      setDistributors(res)
    }
  }

  async function fetchCustomer() {
    setCustomersData([])
    const response = await getAllCustomer()

    if (
      response &&
      response.data &&
      response.data.data &&
      response.data.data.data
    ) {
      let result = response.data.data.data.map((a) => {
        return {
          label: a.name,
          value: a.id,
          contact_number: a.contact_number,
          contact_person: a.contact_person,
          address: a.address,
          company: a.company,
          email: a.email,
        }
      })
      setCustomersData(result)
      setCustomerInfo(result)
    }
  }

  async function fetchUsers() {
    const response = await UseGetRequest("users/get_all_user", {
      screen: "project",
      distributor_id: selectedBuId,
    })
    if (response.data) {
      const formattedData = response.data.data.map((user) => ({
        ...user,
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
      }))
      setUsers(formattedData)
    }
  }

  async function fetchProject() {
    const response = await UseGetRequest(`projects/search`, { project_id: id })
    if (response.data) {
      var data = response.data.data[0]
      setFormValues({
        ...data,
        cost_estimate_amount: data.ce_amount,
        cost_estimate_no: data.ce_no,
        subtotal: parseFloat(data.subtotal),
        grand_total: parseFloat(data.grand_total),
        project_date: dayjs(data.project_date, "YYYY-MM-DD"),
        start_date: dayjs(data.start_date, "YYYY-MM-DD"),
        end_date: dayjs(data.end_date, "YYYY-MM-DD"),
        project_type_name_id_name_id: data.project_type_name_ids?.map(
          (item) => item.project_type_name_id_name_id
        ),
        org_status: data.status,
        user_ids: data?.project_users?.map((user) => user.user_id),
      })
      if (data.attachments && data.attachments.length > 0) {
        const tempFiles = data.attachments?.map((file) => {
          let tempFile = base64ToFile(file.base_64, file.name)
          return tempFile
        })
        setFileAttachments(tempFiles)
      } else {
        setFileAttachments([])
      }
    } else {
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      })
    }
  }

  function sanitizeValues(value) {
    return value.replace(/,/g, "")
  }

  //EDIT FUNCTIONS
  async function handleUpdatePI() {
    if (isSubmitClicked) {
      return
    }

    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] || formValues[key] === undefined) {
        formValues[key] = ""
      }
    })

    // if (validateProject(formValues, setIsError)) {
    setIsSubmitClicked(true)
    const payload = {
      ...formValues,
      project_id: formValues.id,
      project_date: formValues.project_date.format("YYYY-MM-DD"),
      start_date: formValues.start_date.format("YYYY-MM-DD"),
      end_date: formValues.end_date.format("YYYY-MM-DD"),
      file: fileAttachments.map((item) => item),
      cost_estimate_amount: sanitizeValues(formValues.cost_estimate_amount),
      internal_budget: sanitizeValues(formValues.internal_budget),
    }
    const formDataValues = Object.entries(payload).map(([key, value]) => ({
      key,
      value,
    }))

    const response = await UsePostRequest(
      "projects/update",
      formDataValues,
      true
    )
    // const response = await UsePostRequest("projects/update", payload, false)
    if (response.data) {
      toast.success("Successfully updated Project", {
        style: toastStyle(),
      })
      setTimeout(() => navigate(-1), 1000)
    } else {
      setIsSubmitClicked(false)
      toast.error(
        response?.error?.data?.messages?.error ??
          response?.error?.response?.data?.messages?.error ??
          "Something went wrong",
        {
          style: toastStyle(),
        }
      )
    }
  }

  //ADD FUNCTIONS
  async function handleCreateProject() {
    if (isSubmitClicked) {
      return
    }

    Object.keys(formValues).forEach((key) => {
      if (!formValues[key] || formValues[key] === undefined) {
        formValues[key] = ""
      }
    })

    // if (validateProject(formValues, setIsError)) {
    setIsSubmitClicked(true)
    const payload = {
      ...formValues,
      distributor_id: selectedBuId,
      project_date: formValues.project_date.format("YYYY-MM-DD"),
      start_date: formValues.start_date.format("YYYY-MM-DD"),
      end_date: formValues.end_date.format("YYYY-MM-DD"),
      vat_type: "",
      vat_twelve: 0,
      vat_net: 0,
      withholding_tax: 0,
      subtotal: 0,
      grand_total: 0,
      ce_no: ceNumbers,
      file: fileAttachments.map((file) => file),
      cost_estimate_amount: sanitizeValues(formValues.cost_estimate_amount),
      internal_budget: sanitizeValues(formValues.internal_budget),
    }
    const formDataValues = Object.entries(payload).map(([key, value]) => ({
      key,
      value,
    }))

    const response = await UsePostRequest(
      "projects/create",
      formDataValues,
      true
    )
    // const response = await UsePostRequest("projects/create", payload, false)
    if (response.data) {
      if (response.data?.message === "Data already exists") {
        setIsSubmitClicked(false)
        toast.error("Data already exists", { style: toastStyle() })
      } else {
        setIsSubmitClicked(false)
        toast.success("Successfully created Project", {
          style: toastStyle(),
        })
        setTimeout(
          () => navigate("/projectfolder/print/" + response.data.project_id),
          1000
        )
      }
    } else {
      setIsSubmitClicked(false)
      toast.error(response.error.data.messages.error, {
        style: toastStyle(),
      })
    }
    // } else {
    //   toast.error("Please fill in all fields", {
    //     style: toastStyle(),
    //   })
    // }
  }

  //HANDLES
  const handleSubmit = () => {
    if (add) handleCreateProject()
    else if (edit) handleUpdatePI()
    // else if (renew) handleUpdatePI()
  }

  const handleChange = (e, field) => {
    if (field === "customer_id") {
      const tempInfo = customerInfo.filter((info) => info.value === e)
      setFormValues((prevState) => ({
        ...prevState,
        customer_id: e,
        email: tempInfo[0].email ?? "",
        contact_number: tempInfo[0].contact_number ?? "",
        contact_person: tempInfo[0].contact_person ?? "",
        address: tempInfo[0].address ?? "",
        company: tempInfo[0].company ?? "",
      }))
    } else {
      const { name, value } = e.target
      setFormValues((prevState) => ({
        ...prevState,
        [name]: value,
      }))
    }
  }

  //USE EFFECTS
  useEffect(() => {
    if (edit || renew) {
      fetchProject()
    }
    fetchCustomer()
    fetchDistributors()
    fetchUsers()
    // fetchAllProjectTypes();
  }, [])

  // calculate percentage
  // useEffect(() => {
  //   const tempCE = formValues.cost_estimate_amount
  //     ? formValues.cost_estimate_amount
  //     : 0
  //   const tempIB = formValues.internal_budget ? formValues.internal_budget : 0
  //   const tempValue =
  //     (parseFloat(tempCE) - parseFloat(tempIB)) / parseFloat(tempCE)
  //   const finalValue = tempValue * 100
  //   setFormValues((prev) => ({
  //     ...prev,
  //     percentage: parseFloat(finalValue).toFixed(2),
  //   }))
  // }, [formValues.cost_estimate_amount, formValues.internal_budget])

  useEffect(() => {
    //clean the values
    var cleanedInteBudget = formValues.internal_budget
      ? parseFloat(formValues.internal_budget.replace(/,/g, ""))
      : 0
    var cleanedCostEst = formValues.cost_estimate_amount
      ? parseFloat(formValues.cost_estimate_amount.replace(/,/g, ""))
      : 0

    const tempIB = cleanedInteBudget
    const tempCE = cleanedCostEst
    const tempValue =
      (parseFloat(tempCE) - parseFloat(tempIB)) / parseFloat(tempCE)
    const finalValue = tempValue * 100
    setFormValues((prev) => ({
      ...prev,
      percentage: parseFloat(finalValue).toFixed(2),
    }))
  }, [formValues.cost_estimate_amount, formValues.internal_budget])

  React.useEffect(() => {
    const requiredFieldKeys = [
      "name",
      "project_date",
      "customer_id",
      "start_date",
      "end_date",
      // "cost_estimate_no",
      "cost_estimate_amount",
      "internal_budget",
      // "distributor_id",
      // "percentage",
    ]

    let isValid = true

    requiredFieldKeys.forEach((key) => {
      const value = formValues[key]

      if (
        value == null ||
        (typeof value === "string" && value.trim() === "") ||
        (typeof value === "object" && value?.toString().trim() === "")
      ) {
        isValid = false
      }
    })

    setSubmittableForm(isValid && parseFloat(formValues.percentage) >= 0)
  }, [formValues])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => {
            setInactive(inactive)
          }}
          active={"PROJECT FOLDER"}
        />
      </div>

      <div className={`manager-container ${inactive ? "inactive" : "active"}`}>
        <div className="d-flex justify-content-between align-items-center ">
          <h1 className="page-title mb-0">{add ? "ADD " : "EDIT "}PROJECT</h1>
        </div>

        {/* content */}
        <div className="edit-form mt-2">
          {/* FRANCHISEE SALES INVOICE DETAILS */}
          <Fragment>
            <Row className="mb-2">
              {/* <Col xs={12} lg={4}>
                <span className="edit-label">
                  Select Business Unit
                  <label className="badge-required">{` *`}</label>
                </span>
                <Select
                  className="w-100"
                  showSearch
                  filterOption={(input, option) =>
                    option.label?.toLowerCase().includes(input?.toLowerCase())
                  }
                  options={[
                    { value: "0", label: "No Business Unit" },
                    ...distributors,
                  ]}
                  dropdownStyle={{ fontFamily: "var(--primary-font-regular)" }}
                  value={formValues.distributor_id}
                  onChange={(e) => {
                    setFormValues((prevState) => ({
                      ...prevState,
                      distributor_id: e,
                    }))
                  }}
                  disabled={renew}
                />
              </Col> */}
              <Col xs={12} lg={4}>
                <span className="edit-label">
                  Project Name <label className="badge-required">{` *`}</label>
                </span>
                <Form.Control
                  type="text"
                  name="name"
                  value={formValues.name}
                  className="nc-modal-custom-input"
                  onChange={(e) => handleChange(e, "name")}
                  required
                  disabled={renew}
                />
                {/* <InputError
                  isValid={isError.name}
                  message={"Project is required"}
                /> */}
              </Col>
              <Col xs={12} lg={4}>
                <span className="edit-label">
                  Contract Date
                  <label className="badge-required">{` *`}</label>
                </span>
                <DatePicker
                  className="nc-modal-custom-text w-100"
                  value={formValues.project_date}
                  onChange={(e) => {
                    if (e) {
                      setFormValues((prev) => ({
                        ...prev,
                        project_date: e,
                        // project_date: dayjs(e,"YYYY-MM-DD"),
                      }))
                    } else {
                      setFormValues((prev) => ({
                        ...prev,
                        project_date: null,
                        // project_date: dayjs(e,"YYYY-MM-DD"),
                      }))
                    }
                  }}
                />
                {/* <InputError
                  isValid={isError.project_date}
                  message={"Contract date is required"}
                /> */}
              </Col>
              <Col xs={12} lg={4}>
                <span className="edit-label">
                  Start Date - End Date
                  <label className="badge-required">{` *`}</label>
                </span>
                <RangePicker
                  className="nc-modal-custom-text w-100"
                  value={[formValues.start_date, formValues.end_date]}
                  onChange={(e) => {
                    if (e) {
                      setFormValues((prev) => ({
                        ...prev,
                        start_date: e[0],
                        end_date: e[1],
                        // start_date: dayjs(e[0],"YYYY-MM-DD"),
                        // end_date: dayjs(e[1],"YYYY-MM-DD"),
                      }))
                    } else {
                      setFormValues((prev) => ({
                        ...prev,
                        start_date: "",
                        end_date: "",
                      }))
                    }
                  }}
                />
                {/* <InputError
                  isValid={isError.start_date}
                  message={"Start and end date is required"}
                /> */}
              </Col>
            </Row>

            <Row className="mt-4 mb-2">
              <Col xs={12} lg={4}>
                <span className="edit-label">
                  Customer
                  <label className="badge-required">{` *`}</label>
                </span>
                <Select
                  className="w-100"
                  options={customersData}
                  dropdownStyle={{ fontFamily: "var(--primary-font-regular)" }}
                  value={formValues.customer_id}
                  onChange={(e) => handleChange(e, "customer_id")}
                  disabled={renew}
                  showSearch
                  filterOption={(input, option) =>
                    option?.label?.toLowerCase().includes(input.toLowerCase())
                  }
                />
                {/* <InputError
                  isValid={isError.customer_id}
                  message={"Customer is required"}
                /> */}
              </Col>
              <Col xs={12} lg={4}>
                <span className="edit-label">Company</span>
                <Form.Control
                  type="text"
                  name="company"
                  value={formValues.company}
                  className="nc-modal-custom-text"
                  onChange={(e) => handleChange(e, "company")}
                  disabled={renew}
                />
              </Col>
              <Col xs={12} lg={4}>
                <span className="edit-label">Contact Person</span>
                <Form.Control
                  type="text"
                  name="contact_person"
                  value={formValues.contact_person}
                  className="nc-modal-custom-text"
                  onChange={(e) => handleChange(e, "contact_person")}
                  disabled={renew}
                />
                {/* <InputError
                  isValid={isError.contact_person}
                  message={"Contact person is required"}
                /> */}
              </Col>
            </Row>

            <Row className="mt-4 mb-2">
              <Col xs={12} className="mt-0 pt-0">
                <span className="edit-label">Address</span>
                <Form.Control
                  type="text"
                  name="address"
                  className="nc-modal-custom-text"
                  value={formValues.address}
                  onChange={(e) => handleChange(e, "address")}
                  disabled={renew}
                />
                {/* <InputError
                  isValid={isError.address}
                  message={"Address is required"}
                /> */}
              </Col>
            </Row>
            <Row className="mt-4">
              <Col xs={12} lg={4} hidden>
                <Row>
                  <span className="edit-label">
                    Cost Estimate Number/PC Number
                    <label className="badge-required">{` *`}</label>
                  </span>
                </Row>
                <Form.Control
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="cost_estimate_no"
                  value={formValues.cost_estimate_no}
                  className="nc-modal-custom-text"
                  onChange={(e) => handleChange(e, "cost_estimate")}
                  disabled={renew}
                />
                {/* <InputError
                  isValid={isError.cost_estimate_no}
                  message={"Cost estimate is required"}
                /> */}
              </Col>
              <Col xs={12} lg={4}>
                <Row>
                  <span className="edit-label">
                    COST ESTIMATE AMOUNT
                    <label className="badge-required">{` *`}</label>
                  </span>
                </Row>
                <Form.Control
                  type="text"
                  onWheel={(e) => e.target.blur()}
                  name="cost_estimate_amount"
                  value={formValues.cost_estimate_amount}
                  className="nc-modal-custom-text"
                  onChange={(e) => handleChange(e, "cost_estimate_number")}
                  disabled={renew}
                />
              </Col>
              <Col xs={12} lg={4}>
                <Row>
                  <span className="edit-label">
                    Internal Budget
                    <label className="badge-required">{` *`}</label>
                  </span>
                </Row>
                <Form.Control
                  // type="number"
                  // onWheel={(e) => e.target.blur()}
                  name="internal_budget"
                  value={formValues.internal_budget}
                  className="nc-modal-custom-text"
                  onChange={(e) => handleChange(e, "internal_budget")}
                  disabled={renew}
                />
                {/* <InputError
                  isValid={isError.internal_budget}
                  message={"Internal budget is required"}
                /> */}
              </Col>
              <Col xs={12} lg={4}>
                <Row>
                  <span className="edit-label">
                    PERCENTAGE
                    <label className="badge-required">{` *`}</label>
                  </span>
                </Row>
                <Form.Control
                  type="number"
                  onWheel={(e) => e.target.blur()}
                  name="percentage"
                  value={formValues.percentage}
                  className="nc-modal-custom-text"
                  // onChange={(e) => handleChange(e, "percentage")}
                  disabled
                />
                {/* <InputError
                  isValid={isError.percentage}
                  message={"Percentage is required"}
                /> */}
              </Col>
            </Row>

            <Row className="mt-4 mb-2">
              <Col xs={12} className="mb-3">
                <Row className="mb-1">
                  <span className="edit-label">
                    USER
                    <label className="badge-required">{` *`}</label>
                  </span>
                </Row>
                <Select
                  disabled={
                    getType() !== "admin" &&
                    getType() !== "business_unit_finance_head" &&
                    getType() !== "business_unit_finance_manager"
                  }
                  mode="multiple"
                  className="w-100"
                  options={users}
                  classNamePrefix="react-select"
                  showSearch
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  value={formValues.user_ids}
                  onChange={(e) =>
                    setFormValues((prev) => ({
                      ...prev,
                      user_ids: e,
                    }))
                  }
                />
              </Col>
              <Col xs={12} lg={4}>
                <Row className="mb-2">
                  <span className="edit-label">
                    STATUS
                    <label className="badge-required">{` *`}</label>
                  </span>
                </Row>
                <Form.Check
                  inline
                  label="Open"
                  name="status"
                  type="radio"
                  value="open"
                  checked={formValues.status === "open"}
                  onClick={(e) => {
                    if (e.target.checked) {
                      setFormValues((prev) => ({
                        ...prev,
                        status: "open",
                      }))
                    }
                  }}
                  disabled={
                    formValues.org_status === "closed" && getUserId() !== "144"
                  }
                />
                <Form.Check
                  inline
                  label="Closed"
                  name="status"
                  type="radio"
                  value="closed"
                  checked={formValues.status === "closed"}
                  onClick={(e) => {
                    if (e.target.checked) {
                      setFormValues((prev) => ({
                        ...prev,
                        status: "closed",
                      }))
                    }
                  }}
                />
                <Form.Check
                  inline
                  label="Cancelled"
                  name="status"
                  type="radio"
                  value="cancelled"
                  checked={formValues.status === "cancelled"}
                  onClick={(e) => {
                    if (e.target.checked) {
                      setFormValues((prev) => ({
                        ...prev,
                        status: "cancelled",
                      }))
                    }
                  }}
                />
                {/* <InputError
                  isValid={isError.percentage}
                  message={"Percentage is required"}
                /> */}
              </Col>
            </Row>

            <Row className="mt-4 mb-2">
              <span className="edit-label">File Attachments</span>
              <Col xs={12}>
                <Dragger
                  {...{
                    // style: { width: 550 },
                    fileAttachments,
                    fileList: fileAttachments,
                    onRemove: (selectedFile) => {
                      var newlist = fileAttachments.filter((file) => {
                        return selectedFile.uid !== file.uid
                      })
                      setFileAttachments(newlist)
                    },
                    beforeUpload: (file) => {
                      const isSizeValid = file.size / 1024 / 1024 < 1 // Check if file size is less than 1MB
                      if (!isSizeValid) {
                        toast.error("File cannot exceed 1MB")
                        return Upload.LIST_IGNORE
                      } else {
                        setFileAttachments([...fileAttachments, file])
                      }
                      return false
                    },
                    multiple: true,
                    // onChange: handleOnChange,
                    listType: "picture",
                    progress: { showInfo: true },
                  }}
                >
                  <img src={upload} className="cursor-pointer" alt="" />
                  <p className="ant-upload-text">
                    Click or drag file to this area to upload
                  </p>
                </Dragger>
              </Col>
            </Row>
          </Fragment>

          {/* <Row className="mt-4 pt-3">
            <span className="edit-label mb-2">ONE TIME FEE</span>
            <div className="edit-purchased-items">
              {oneTimeFees.length !== 0 ? (
                <>
                  <Table>
                    <thead>
                      <tr>
                        <th className="color-gray">Descriptions</th>
                        <th className="color-gray">Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {oneTimeFees.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <Form.Control
                                type="text"
                                name="description"
                                defaultValue={item.description}
                                // value={item.descriptions}
                                onChange={(e) => handleCostChange(e, index)}
                              />
                              <InputError
                                isValid={isError.description}
                                message={"Cost is required"}
                              />
                            </td>

                            <td>
                              <Form.Control
                                type="number" onWheel={(e)=>e.target.blur()}
                                name="amount"
                                defaultValue={item.amount}
                                // value={item.amount}
                                onChange={(e) => handleCostChange(e, index)}
                                onKeyPress={(e) => handleKeyPress(e)}
                              />
                              <InputError
                                isValid={isError.amount}
                                message={"Cost is required"}
                              />
                            </td>
                            <td className="text-center">
                              <img
                                src={trash}
                                onClick={() => handleDelRowCost(index)}
                                className="cursor-pointer"
                                alt=""
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <InputError
                    isValid={isError.item_info}
                    message={"Please make sure all fields are filled"}
                  />
                </>
              ) : (
                <div className="entries-not-found">
                  There is no data recorded yet.
                </div>
              )}
            </div>
          </Row>
          <InputError
            isValid={isError.received_items_table}
            message={"Either Recurring or One Time Fees is required"}
          /> */}

          {/* <Row className="pt-3 PO-add-item">
            <Button type="button" onClick={() => handleAddNewRowCost()}>
              Add One Time Fee
            </Button>
          </Row>
          <Row className="mt-4 pt-3">
            <span className="edit-label mb-2">Recurring Fee</span>
            <div className="edit-purchased-items">
              {recurring.length !== 0 ? (
                <>
                  <Table>
                    <thead>
                      <tr>
                        <th className="color-gray">Descriptions</th>
                        <th className="color-gray">Type</th>
                        <th className="color-gray">Periods</th>
                        <th className="color-gray">Amount</th>
                        <th className="color-gray">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recurring.map((item, index) => {
                        return (
                          <tr>
                            <td>
                              <Form.Control
                                type="text"
                                name="description"
                                value={item.description}
                                onChange={(e) =>
                                  handleRecurringChange(e, index)
                                }
                              />
                            </td>
                            <td>
                              <Form.Select
                                name="type"
                                value={item.type}
                                onChange={(e) => {
                                  handleRecurringChange(e, index);
                                }}
                              >
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="monthly">Monthly</option>
                                <option value="yearly">Yearly</option>
                              </Form.Select>
                            </td>
                            <td>
                              <Row>
                                <Col>
                                  <Form.Control
                                    type="number" onWheel={(e)=>e.target.blur()}
                                    name="periods"
                                    value={item.periods}
                                    onChange={(e) => {
                                      if (!renew) {
                                        handleRecurringChange(e, index);
                                      }
                                    }}
                                  />
                                </Col>
                                <Col>
                                  <Form.Control
                                    type="text"
                                    name="suffix"
                                    disabled
                                    value={item.suffix ?? ""}
                                  />
                                </Col>
                              </Row>
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                name="prices"
                                defaultValue={item.prices}
                                // value={item.price}
                                onChange={(e) =>
                                  handleRecurringChange(e, index)
                                }
                                onKeyPress={(e) => handleKeyPress(e)}
                              />
                              <InputError
                                isValid={isError.prices}
                                message={"Cost is required"}
                              />
                            </td>
                            <td className="text-center">
                              <img
                                src={trash}
                                onClick={() => handleDelRow(index)}
                                className="cursor-pointer"
                                alt=""
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                  <InputError
                    isValid={isError.item_info}
                    message={"Please make sure all fields are filled"}
                  />
                </>
              ) : (
                <div className="entries-not-found">
                  There is no Recurring Fee recorded yet.
                </div>
              )}
              <InputError
                isValid={isError.items}
                message={"Please add at least 1 Recurring"}
              />
            </div>
          </Row>
          <InputError
            isValid={isError.received_items_table}
            message={"Either Recurring or One Time Fees is required"}
          /> */}

          {/* <Row className="pt-3 PO-add-item">
            <Button type="button" onClick={() => handleAddNewRow()}>
              Add Recurring Fee
            </Button>
          </Row> */}

          {/* <Row className="align-right pt-3">
            <Col xs={2} className="text-end">
              <span className="edit-label color-gray">One Time Fees</span>
            </Col>
            <Col xs={1} className="text-end">
              <span className="edit-label align-middle">PHP</span>
            </Col>
            <Col xs={3} className="text-end">
              <Form.Control
                // type="number" onWheel={(e)=>e.target.blur()}
                name="one_time_fees_total"
                min={0}
                step="0.01"
                value={formatAmount(formValues.one_time_fees_total) || 0}
                className="align-middle nc-modal-custom-text text-end"
                // onChange={(e) => handleChange(e)}
                disabled
              />
            </Col>
          </Row>
          <Row className="align-right pt-3">
            <Col xs={2} className="text-end">
              <span className="edit-label color-gray">Recurring Fees</span>
            </Col>
            <Col xs={1} className="text-end">
              <span className="edit-label align-middle">PHP</span>
            </Col>
            <Col xs={3} className="text-end">
              <Form.Control
                // type="number" onWheel={(e)=>e.target.blur()}
                name="recurring_cost_total"
                min={0}
                step="0.01"
                value={formatAmount(formValues.recurring_cost_total) || 0}
                className="align-middle nc-modal-custom-text text-end"
                disabled
                // onChange={(e) => handleChange(e)}
              />
            </Col>
          </Row>
          <Row className="align-right pt-3">
            <Col className="text-end">
              <Form.Check
                inline
                label="VAT Ex"
                name="vat_type"
                type="radio"
                value="vat_ex"
                checked={formValues.vat_type === "vat_ex"}
                onClick={(e) => {
                  setFormValues((prev) => ({
                    ...prev,
                    vat_type: "vat_ex",
                  }));
                }}
                disabled={renew}
              />
              <Form.Check
                inline
                label="VAT In"
                name="vat_type"
                type="radio"
                value="vat_in"
                checked={formValues.vat_type === "vat_in"}
                onClick={(e) => {
                  setFormValues((prev) => ({
                    ...prev,
                    vat_type: "vat_in",
                  }));
                }}
                disabled={renew}
              />
            </Col>
          </Row>

          {formValues.vat_type === "vat_in" && (
            <>
              <Row className="align-right pt-3">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray">Subtotal</span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label vat-total-text align-middle">
                    PHP
                  </span>
                </Col>
                <Col xs={3} className="text-end">
                  <span className="edit-label align-middle vat-total-text text-end">
                    {formatAmount(formValues.subtotal)}
                  </span>
                </Col>
              </Row>
              <Row className="align-right pt-3">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray">Net of VAT</span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label vat-total-text align-middle">
                    PHP
                  </span>
                </Col>
                <Col xs={3} className="text-end">
                  <span className="edit-label align-middle vat-total-text text-end">
                    {formatAmount(formValues.vat_net)}
                  </span>
                </Col>
              </Row>
              <Row className="align-right pt-3">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray">12% VAT</span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label vat-total-text align-middle">
                    PHP
                  </span>
                </Col>
                <Col xs={3} className="text-end">
                  <span className="edit-label align-middle vat-total-text text-end">
                    {formatAmount(formValues.vat_twelve)}
                  </span>
                </Col>
              </Row>
              <Row className="align-right pt-3">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray">WHT %</span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label vat-total-text align-middle">
                    PHP
                  </span>
                </Col>
                <Col xs={3} className="text-end">
                  <span className="">
                    <Select
                      placeholder="SELECT %"
                      className="w-90"
                      value={formValues.wht_percent}
                      options={[
                        { value: 0, label: "0" },
                        { value: 1, label: "1" },
                        { value: 2, label: "2" },
                        { value: 5, label: "5" },
                        { value: 10, label: "10" },
                        { value: 15, label: "15" },
                      ]}
                      onChange={(e) =>
                        setFormValues((prev) => ({
                          ...prev,
                          wht_percent: e,
                        }))
                      }
                    />
                  </span>
                </Col>
              </Row>
              <Row className="align-right pt-3">
                <Col xs={2} className="text-end">
                  <span className="edit-label color-gray">WHT AMOUNT</span>
                </Col>
                <Col xs={1} className="text-end">
                  <span className="edit-label vat-total-text align-middle">
                    PHP
                  </span>
                </Col>
                <Col xs={3} className="text-end">
                  <span className="edit-label align-middle vat-total-text text-end">
                    {formatAmount(formValues.withholding_tax)}
                  </span>
                </Col>
              </Row>
            </>
          )}
          <Row className="align-right py-5">
            <Col xs={2} className="text-end">
              <span className="edit-label color-gray grand-total-text">
                Grand Total
              </span>
            </Col>
            <Col xs={1} className="text-end">
              <span className="edit-label align-middle grand-total-text">
                PHP
              </span>
            </Col>
            <Col xs={3} className="text-end">
              <span className="edit-label align-middle grand-total-text text-end">
                {formatAmount(formValues.grand_total)}
              </span>
            </Col>
          </Row> */}

          {/* FOOTER: CANCEL & SUBMIT BUTTONS */}
          <div className="d-flex justify-content-end pt-5 pb-3">
            <button
              type="button"
              className="button-secondary me-3"
              onClick={() => navigate(-1)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="button-primary"
              onClick={handleSubmit}
              disabled={isSubmitClicked || !submittableForm}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

FormProject.defaultProps = {
  add: false,
  edit: false,
}

export default FormProject

// DO NOT DELETE KAY USEFUL NI FOR LATER
// useEffect(() => {
//   if (formValues.vat_type === "vat_in") {
//     const grossTotal =
//       parseFloat(formValues.recurring_cost_total) +
//       parseFloat(formValues.one_time_fees_total);
//     var tempVatNet = parseFloat(grossTotal / 1.12);
//     const tempTax =
//       tempVatNet * (parseFloat(formValues.wht_percent) / 100);
//     var temp12 = parseFloat(tempVatNet * 0.12);

//     setFormValues((prev) => ({
//       ...prev,
//       subtotal: grossTotal,
//       vat_net: tempVatNet,
//       vat_twelve: temp12,
//       withholding_tax: tempTax,
//       grand_total: parseFloat(grossTotal - tempTax),
//     }));
//   } else {
//     const grossTotal =
//       parseFloat(formValues.recurring_cost_total) +
//       parseFloat(formValues.one_time_fees_total);
//     const tempTax =
//       grossTotal * (parseFloat(formValues.wht_percent) / 100);

//     setFormValues((prev) => ({
//       ...prev,
//       subtotal: grossTotal,
//       vat_net: 0,
//       vat_twelve: 0,
//       wht_percent: 0,
//       withholding_tax: 0,
//       grand_total: grossTotal,
//     }));
//   }
// }, [
//   formValues.recurring_cost_total,
//   formValues.one_time_fees_total,
//   formValues.vat_type,
//   formValues.wht_percent,
// ]);
