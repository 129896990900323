import { SyncLoader } from "react-spinners"
import DataTable from "react-data-table-component"
import NoDataPrompt from "../NoDataPrompt/NoDataPrompt"
import "./Table.css"

export default function Table({
  OngoingPOBadges,
  tableHeaders,
  headerSelector,
  tableData,
  ActionBtn,
  PendingBtn,
  ViewBtn,
  showLoader,
  withActionData,
  PayBtn,
  conditionalRowStyles,
  handleRowClick,
  screen,
  isPointer = true,
  selectableRows = false,
  setSelectedRows,
}) {
  const widthMapping = {
    "-": "7vw",
    "*": "7vw",
    "PRINT TEMPLATE": "20vw",
    CLASSIFICATION: "20vw",
    USERNAME: "15vw",
    "FRANCHISE DATE": "10vw",
    ADDRESS: "15vw",
    FRANCHISEE: "15vw",
    "BRANCH NAME": "15vw",
    ACTIONS: "14vw",
    " ": "10vw",
    "ACCOUNT NO.": "15vw",
    "NO.": "2vw",
    SUPPLIER: "15vw",
    PAYEE: "20vw",
    "PROJECT NAME": "15vw",
    CUSTOMER: "15vw",
    "CUSTOMER NAME": "20vw",
    "START DATE": "15vw",
    "CONTRACT DATE": "13vw",
    "START DATE - END DATE": "20vw",
    "BUSINESS UNIT": "15vw",
    DATE: "15vw",
    "APPR BY": "18vw",
    "ADDED BY": "20vw",
    "CREATED BY": "20vw",
    "REVIEWED BY": "20vw",
    "APPROVED BY": "20vw",
    "RELEASED BY": "20vw",
    "RECEIVED BY": "20vw",
    "FINALIZED BY": "20vw",
    "DISAPPROVED BY": "20vw",
    "SENT BY": "20vw",
    "SE NO.": "5vw",
    "REQUEST DATE": "10vw",
    "DUE DATE": "10vw",
    AMOUNT: "5vw",
    "ITEM NAME": "15vw",
    "ITEM CODE": "15vw",
    WAREHOUSE: "15vw",
    ITEM: "12vw",
    ACTIVITY: "15vw",
    QUANTITY: "10vw",
    RUNNING: "11vw",
    "BEGINNING INVENTORY": "15vw",
    "CURRENT INVENTORY": "15vw",
    REQUISITIONER: "15vw",
    REMARKS: "10vw",
    DETAILS: "20vw",
    RETURNEE: "15vw",
  }

  const defaultWidth = "auto"

  const columns = tableHeaders.map((header, index) => {
    const commonProps = {
      name: header,
      selector: (row) => row[headerSelector[index]] || "",
      reorder: true,
      width: widthMapping[header] || defaultWidth,
    }

    switch (header) {
      case "-":
        return { ...commonProps, button: true, cell: ViewBtn, reorder: false }
      case "*":
        return { ...commonProps, button: true, cell: PayBtn, reorder: false }
      case "ACTIONS":
        return !withActionData
          ? { ...commonProps, button: true, cell: ActionBtn, left: true }
          : commonProps
      case " ":
        return { ...commonProps, button: true, cell: PendingBtn }
      case "PROJECT EXPENSE":
        return {
          ...commonProps,
          button: true,
          cell: OngoingPOBadges,
          footer: (tableData) => {
            const total = tableData.reduce(
              (acc, val) => acc + (val ? parseFloat(val.amount) : 0),
              0
            )
            return `Total: ${total.toFixed(2)}`
          },
        }
      case "USERNAME":
        return {
          ...commonProps,
          selector: (row) =>
            row[headerSelector[index]] !== null
              ? row[headerSelector[index]].toLowerCase()
              : "",
        }
      case "BUSINESS UNITS":
        return {
          ...commonProps,
          selector: (row) => {
            const businessUnits = row.business_units || []
            return (
              <ul className="my-1">
                {businessUnits.map((unit) => (
                  <li key={unit.business_unit_id}>{unit.business_unit_name}</li>
                ))}
              </ul>
            )
          },
        }
      case "SUBTYPE":
        return {
          ...commonProps,
          selector: (row) =>
            row[headerSelector[index]].map((type) => (
              <li className="mt-1">{type.name}</li>
            )),
        }
      case "ACTIVITY":
        return {
          ...commonProps,
          cell: (row) => (
            <div style={{ whiteSpace: "pre-wrap" }}>
              {row[headerSelector[index]]}
            </div>
          ),
        }
      case "RETURNEE":
        return {
          ...commonProps,
          cell: (row) => (
            <div style={{ whiteSpace: "pre-wrap" }}>
              {row[headerSelector[index]]}
            </div>
          ),
        }
      case "APPROVED BY":
        return {
          ...commonProps,
          cell: (row) => (
            <div style={{ whiteSpace: "pre-wrap" }}>
              {row[headerSelector[index]]}
            </div>
          ),
        }
      case "RECEIVED BY":
        return {
          ...commonProps,
          cell: (row) => (
            <div style={{ whiteSpace: "pre-wrap" }}>
              {row[headerSelector[index]]}
            </div>
          ),
        }
      case "RELEASED BY":
        return {
          ...commonProps,
          cell: (row) => (
            <div style={{ whiteSpace: "pre-wrap" }}>
              {row[headerSelector[index]]}
            </div>
          ),
        }
      case "DISAPPROVED BY":
        return {
          ...commonProps,
          cell: (row) => (
            <div style={{ whiteSpace: "pre-wrap" }}>
              {row[headerSelector[index]]}
            </div>
          ),
        }
      case "REQUISITIONER":
        return {
          ...commonProps,
          cell: (row) => (
            <div style={{ whiteSpace: "pre-wrap" }}>
              {row[headerSelector[index]]}
            </div>
          ),
        }
      case "QUANTITY":
        return {
          right: true,
          ...commonProps,
        }
      case "RUNNING":
        return {
          right: true,
          ...commonProps,
        }
      case "FRANCHISE DATE":
      case "ACCOUNT NO.":
      case "NO.":
        return { ...commonProps, sortable: true }
      case "CURRENT INVENTORY":
      case "BEGINNING INVENTORY":
        return { ...commonProps, left: true, sortable: false }
      default:
        return header === "AMOUNT" ||
          header === "PAID AMOUNT" ||
          header === "OTHER FEES" ||
          header === "GRAND TOTAL" ||
          header === "RECEIVABLE" ||
          header === "CREDIT" ||
          header === "BALANCE" ||
          header === "TOTAL" ||
          header === "PACKAGE" ||
          header === "DOC. NO" ||
          header === "INVCE NO." ||
          header === "TOTAL SALES" ||
          header === "TOTAL PROFIT" ||
          header === "PRICE" ||
          header === "BUDGET" ||
          header === "ACTUAL EXPENSE" ||
          header === "TOTAL AMOUNT" ||
          header === "OTHER FEES" ||
          header === "INTERNAL BUDGET" ||
          header === "COST ESTIMATE" ||
          header === "ACTUAL COST"
          ? {
              ...commonProps,
              sortable: true,
              right: true,
              width: "10vw",
            }
          : commonProps
    }
  })

  const paginationComponentOptions = {
    rowsPerPageText: "",
    noRowsPerPage: true,
  }

  const customStyles = {
    rows: {
      style: {
        minHeight: "5.2vh",
      },
    },
  }

  return showLoader ? (
    <div className="d-flex justify-content-center my-5">
      <SyncLoader color="var(--primary-color)" size={15} />
    </div>
  ) : (
    <DataTable
      pagination={
        screen === "business_unit" ||
        screen === "banks" ||
        screen === "chart_of_account"
          ? false
          : true
      }
      striped
      fixedHeader
      fixedHeaderScrollHeight="80vh"
      columns={columns}
      data={tableData}
      customStyles={customStyles}
      paginationComponentOptions={paginationComponentOptions}
      noDataComponent={<NoDataPrompt />}
      conditionalRowStyles={conditionalRowStyles}
      onRowClicked={handleRowClick}
      className={isPointer ? "pointer" : ""}
      selectableRows={!!selectableRows}
      onSelectedRowsChange={(state) => setSelectedRows(state.selectedRows)}
    />
  )
}
