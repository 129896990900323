import React, { useContext, useEffect, useState } from "react"
import Navbar from "../../Components/Navbar/Navbar"
import { Col, Form, Row } from "react-bootstrap"
import AddModal from "../../Components/Modals/AddModal"
import ViewModal from "../../Components/Modals/ViewModal"
import Table from "../../Components/TableTemplate/DataTable"
import DeleteModal from "../../Components/Modals/DeleteModal"
import downloadIcon from "../../Assets/Images/download_icon.png"

//API CALL HELPERS
import { UsePostRequest } from "../../Helpers/hooks/UsePostApiCall"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import Select from "react-select"
import {
  dateWithTimeFormat,
  numberFormatWithoutDecimal,
  refreshPage,
  toastStyle,
} from "../../Helpers/Utils/Common"
import toast from "react-hot-toast"
import { Context } from "../../Helpers/Context/Context"

const ItemHistory = () => {
  const [inactive, setInactive] = useState(true)
  const [isClicked, setIsClicked] = useState(false)
  const [showModal, setShowModal] = useState("")
  const [itemOptions, setItemOptions] = useState([])
  const { selectedBuId, warehouseId } = useContext(Context)
  const [exportToExcelLink, setExportToExcelLink] = useState("")
  const [submittableForm, setSubmittableForm] = useState(false)
  const [details, setDetails] = useState({
    name: "",
  })

  const [tableData, setTableData] = useState([])
  const [showLoader, setShowLoader] = useState(false)

  function handleChange(e, fieldType) {
    const { name, value } = e.target || {}
    let updatedDetails = {}

    if (fieldType === "input") {
      updatedDetails = { ...details, [name]: value }
    } else if (fieldType === "dropdown") {
      updatedDetails = { ...details, [e.for]: e.value }
    }
    setDetails(updatedDetails)
    validate(updatedDetails)
  }

  function validate(formDetails) {
    const { item_id, quantity } = formDetails
    const isFormValid = item_id && quantity && quantity > 0
    setSubmittableForm(isFormValid)
  }

  async function handleCreate() {
    setIsClicked(true)
    const response = await UsePostRequest(
      "stock_ins/create",
      { ...details },
      false
    )

    if (response.error) {
      toast.error(`Something went wrong.`, {
        style: toastStyle(),
      })
    } else {
      toast.success(response?.data?.response, {
        style: toastStyle(),
      })
      setTimeout(() => {
        refreshPage()
      }, 1000)
    }
    setIsClicked(false)
  }
  async function handleDelete() {}

  async function fetchTableData(id) {
    setShowLoader(true)

    const response = await UseGetRequest("items/get_item_history", {
      item_id: id,
      warehouse_id: warehouseId,
      business_unit_id: selectedBuId,
    })

    if (response.data) {
      const data = response.data.data.map((item) => {
        const info = { ...item }
        let stock_out_label = ""
        if (item.activity === "stock_out") {
          stock_out_label = `Pull Out\nReq: ${item.requisitioner || ""}\nBU: ${
            item.business_unit_name || ""
          }`
        }

        let stock_in_label = ""
        if (item.activity === "stock_in") {
          stock_in_label = `Stock In\nReturnee: ${item.returnee || ""}\nBU: ${
            item.business_unit_name || ""
          }`
        }

        let beginning_inventory_label = ""
        if (item.activity === "beginning_inventory") {
          beginning_inventory_label = `Beginning Inventory\nAdded By: ${
            item.added_by_name || ""
          }\nBU: ${item.business_unit_name || ""}`
        }
        info.remarks =
          item.activity === "beginning_inventory"
            ? item.remarks
            : item.remark_item
        info.date = dateWithTimeFormat(item.date)
        info.quantity = numberFormatWithoutDecimal(item.quantity)
        info.running_quantity = numberFormatWithoutDecimal(
          item.running_quantity
        )
        info.label =
          item.activity === "stock_out"
            ? stock_out_label
            : item.activity === "stock_in"
            ? stock_in_label
            : item.activity === "beginning_inventory"
            ? beginning_inventory_label
            : ""
        return info
      })

      setShowLoader(false)
      setTableData(data)
    } else {
      setShowLoader(false)
      setTableData([])
    }
  }

  async function fetchItems() {
    const response = await UseGetRequest("items/get_all_item", {
      warehouse_id: warehouseId,
      business_unit_id: selectedBuId,
    })

    if (response.data) {
      const items = response.data.data.map((item) => ({
        ...item,
        for: "item_id",
        label: item.name,
        value: item.id,
      }))

      setItemOptions(items)
    } else {
      setItemOptions([])
    }
  }

  async function createExcelFile(id) {
    const response = await UseGetRequest(`items/create_item_history_excel`, {
      warehouse_id: warehouseId,
      business_unit_id: selectedBuId,
      item_id: id || "",
    })
    if (response.data?.response === "Successful.") {
      setExportToExcelLink(response.data.url)
    } else {
      toast.error(response.error?.data?.messages?.error, {
        style: toastStyle(),
      })
    }
  }

  useEffect(() => {
    fetchItems()
  }, [])

  return (
    <div>
      <div className="page">
        <Navbar
          onCollapse={(inactive) => setInactive(inactive)}
          active={"ITEM HISTORY"}
        />
      </div>
      <div
        className={`manager-container pt-4 ${inactive ? "inactive" : "active"}`}
      >
        <Row className="mb-1 d-flex flex-row gap-2 gap-lg-0">
          <Col xs={12} lg={10}>
            <h1 className="page-title m-0 w-full">ITEM HISTORY</h1>
          </Col>
          <Col xs={12} lg={2} className="d-flex justify-content-end">
            <div className="button-primary py-2 w-full text-center">
              <span className="me-2">
                <img width={20} height={20} src={downloadIcon} alt=""></img>
              </span>
              <a href={exportToExcelLink} className="pointer custom-anchor">
                Export to Excel
              </a>
            </div>
          </Col>
        </Row>
        <Row className="mb-1 d-flex flex-row gap-2 gap-lg-0 mt-3">
          <Col xs={12} lg={10}>
            <Select
              className="dropsearch-filter px-0 py-0 me-2"
              classNamePrefix="react-select"
              placeholder="Select"
              onChange={(e) => {
                fetchTableData(e.id)
                createExcelFile(e.id)
              }}
              options={itemOptions}
            />
          </Col>
        </Row>
        <Row className="tab-content pt-3 mt-3 ms-1">
          <Col>
            <div className="below">
              <Table
                tableHeaders={[
                  "DATE",
                  "ITEM",
                  "ACTIVITY",
                  "QUANTITY",
                  "RUNNING",
                  "REMARKS",
                  "APPR BY",
                ]}
                headerSelector={[
                  "date",
                  "item_name",
                  "label",
                  "quantity",
                  "running_quantity",
                  "remarks",
                  "approved_by_name",
                ]}
                tableData={tableData}
                showLoader={showLoader}
                handleRowClick={(row) => {
                  setDetails(row)
                  setShowModal("view")
                }}
              />
            </div>
          </Col>
        </Row>
      </div>
      <AddModal
        title="STOCK IN"
        size="xl"
        type="branch"
        show={showModal === "add"}
        onHide={() => setShowModal("")}
        onSave={handleCreate}
        isClicked={isClicked}
        disableSave={!submittableForm}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              ITEM <span className="required-icon">*</span>
              <Select
                name="item_id"
                className="w-full"
                classNamePrefix="react-select"
                placeholder="Select item"
                onChange={(e) => handleChange(e, "dropdown")}
                options={itemOptions}
              />
            </Col>
            <Col>
              QUANTITY<span className="required-icon">*</span>
              <Form.Control
                name="quantity"
                type="number"
                onWheel={(e) => e.target.blur()}
                onChange={(e) => handleChange(e, "input")}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              REMARKS
              <Form.Control
                name="remarks"
                type="text"
                onChange={(e) => handleChange(e, "input")}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
        </div>
      </AddModal>
      <ViewModal
        withHeader={true}
        title="ITEM HISTORY"
        withButtons
        size="lg"
        editable={false}
        show={showModal === "view"}
        onHide={() => setShowModal("")}
        onEdit={() => {
          setShowModal("edit")
        }}
        onDelete={() => setShowModal("delete")}
      >
        <div className="d-flex justify-content-center align-items-center flex-column">
          <Row className="nc-modal-custom-row d-flex flex-column flex-md-row gap-3 gap-md-0">
            <Col>
              NAME<span className="required-icon">*</span>
              <Form.Control
                type="text"
                disabled
                value={details.item_name}
                className="nc-modal-custom-input-edit"
              />
            </Col>
            <Col>
              QUANTITY<span className="required-icon">*</span>
              <Form.Control
                name="quantity"
                type="number"
                onWheel={(e) => e.target.blur()}
                disabled
                value={details.quantity}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
          <Row className="nc-modal-custom-row">
            <Col>
              REMARKS
              <Form.Control
                name="remarks"
                type="text"
                disabled
                value={details.remarks}
                className="nc-modal-custom-input-edit"
              />
            </Col>
          </Row>
        </div>
      </ViewModal>
      <DeleteModal
        text="item"
        show={showModal === "delete"}
        onHide={() => setShowModal("edit")}
        onDelete={handleDelete}
      />
    </div>
  )
}

export default ItemHistory
