import React, { useContext, useEffect, useState } from "react"
import { Col, Row } from "react-bootstrap"
import { BarLoader } from "react-spinners"
import { useNavigate } from "react-router-dom"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import { UseGetRequest } from "../../Helpers/hooks/GetRequests"
import { Context } from "../../Helpers/Context/Context"
import GeneralWh from "../../Assets/Images/general-warehouse.jpg"
import ColorThief from "colorthief"

export default function BuPerWarehouse() {
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const [businessUnits, setBusinessUnits] = useState([])
  const {
    setSelectedBuId,
    setBuName,
    setWarehouseName,
    setWarehouseId,
    setBusinessUnitLogo,
    // setPrimaryColor,
  } = useContext(Context)

  async function fetchBusinessUnits() {
    setBusinessUnits([])
    setLoading(true)
    const response = await UseGetRequest("distributors/get_all_distributor", {
      with_warehouse: 1,
    })
    if (response.data && Array.isArray(response.data.data)) {
      let data = response.data.data.map((bu) => {
        let info = bu
        info.logo = bu.distributor_attachment[0].file_url
        return info
      })
      setBusinessUnits(data)
    } else {
      setBusinessUnits([])
    }
    setLoading(false)
  }

  function changeTheme(image) {
    const img = new Image()
    img.crossOrigin = "Anonymous"
    img.src = image

    img.onload = () => {
      const colorThief = new ColorThief()
      const dominantColor = colorThief.getColor(img)
      const hexColor = rgbToHex(dominantColor)
      // setPrimaryColor(hexColor)
      localStorage.setItem("primaryColor", hexColor)
    }
  }

  const rgbToHex = (rgb) => {
    return `#${rgb.map((x) => x.toString(16).padStart(2, "0")).join("")}`
  }

  async function convertToBase64(imageUrl) {
    return fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => {
            resolve(reader.result) // Get Base64 string after comma
          }
          reader.onerror = (error) => {
            reject(error)
          }
          reader.readAsDataURL(blob)
        })
      })
  }

  useEffect(() => {
    fetchBusinessUnits()
    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }, [])

  return (
    <div className="landing-page-container">
      <div className="fixed-top landing-page-header">
        <Row className="d-flex flex-row justify-content-center align-items-center">
          <Col
            className="d-flex flex-row text-white justify-content-start align-items-center pe-4 font-primary cursor gap-2 me-5 px-5 pt-3"
            onClick={() => navigate(`/landing-page  `)}
          >
            <ArrowBackIcon />
            <p className="font-primary m-0 text-white">BACK</p>
          </Col>
          <Col className="w-full d-flex landing-page-logo ms-2 px-5">
            {/* <img src={Logo} alt="" /> */}
          </Col>
        </Row>
      </div>
      <div className="bu-units-container">
        {loading ? (
          <Row className="d-flex flex-row justify-content-center align-items-center w-full mt-5 pt-5">
            <BarLoader color="white" />
          </Row>
        ) : (
          <Row className="d-flex flex-wrap px-5 pt-2 card-row overflow-scroll">
            {Array.isArray(businessUnits) &&
              businessUnits.length !== 0 &&
              businessUnits.map((bu) => (
                <Col
                  key={bu.id}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  className="p-3 position-relative"
                  onClick={() => {
                    setSelectedBuId(bu.id)
                    setBuName(bu.name)
                    navigate(`/warehouse-list`)
                  }}
                >
                  <div className="card-container d-flex flex-column justify-content-center align-items-center">
                    <img src={bu.logo} alt="" className="m-3" />
                    <h6 className="m-0 px-3 pb-2 text-center font-primary">
                      {bu.name}
                    </h6>
                  </div>
                </Col>
              ))}
            <Col xs={12} sm={6} md={4} lg={3}>
              <div className="card-container d-flex flex-column justify-content-center align-items-center h-full py-1">
                <img
                  src={GeneralWh}
                  alt=""
                  className="m-3 py-2"
                  onClick={() => {
                    changeTheme(GeneralWh)
                    convertToBase64(GeneralWh)
                      .then((base64) => {
                        setBusinessUnitLogo(base64)
                        setSelectedBuId("")
                        navigate(`/items-list`)
                      })
                      .catch((error) => {
                        console.error(
                          "Error converting image to Base64:",
                          error
                        )
                      })
                    setWarehouseName("general warehouse")
                    setWarehouseId("")
                  }}
                />
                <h6 className="m-0 px-3 pb-1 text-center font-primary">
                  Consolidated Warehouse
                </h6>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  )
}
